const cdiscountExperience = {
    id: 'EXP_Cdiscount',
    startDate: new Date("2020-12-01T00:00:00.000+01:00"),
    endDate: null,
    position: 'Lead Developer',
    company: 'Cdiscount',
    companyLogo: 'cdiscount',
    contract: 'Permanent', // != Fixed-term 
    city: 'Bordeaux',
    country: 'France',
    collapseInfos: {
        description: 'Design, develop, test and maintain the technical components related to sponsored products',
        technosSvg:["csharp","netcore","apirest","visualstudio","microservices","kafka","kubernetes","mongodb","sonar","sqlserver","specflow","swagger","xunit","jira"],
        technos:["C#",".Net Core","API REST","Visual Studio","Microservices","Kafka","Kubernetes","MongoDB", "Java", "Solr","Sonar","SQL server","Specflow","Swagger","XUnit","Jira"]
    }
}

const siiExperience = {
    id: 'EXP_SII',
    startDate: new Date("2018-10-15T00:00:00.000+01:00"),
    endDate: new Date("2020-11-30T00:00:00.000+01:00"),
    position: 'Consulting Engineer',
    company: 'SII Group',
    companyLogo: 'sii',
    contract: 'Permanent', 
    city: 'Mérignac',
    country: 'France',
    collapseInfos: {
        columnInfos : ["Client","City","Country","Period"],
        columnInfosMobile : ["Client"],
        missions: [{
            id: 'MIS_cdiscount',
            client: 'Cdiscount',
            companyLogo: 'cdiscount',
            city: "Bordeaux",
            country: "France",
            startDate: new Date("2019-01-14T00:00:00.000+01:00"),
            endDate: new Date("2020-11-30T00:00:00.000+01:00"),
            collapseInfos: {
                description: 'Design, develop, test and maintain the technical components related to sponsored products',
                technosSvg:["csharp","netcore","apirest","visualstudio","microservices","kafka","kubernetes","mongodb","sonar","sqlserver","specflow","swagger","xunit","jira"],
                technos:["C#",".Net Core","API REST","Visual Studio","Microservices","Kafka","Kubernetes","MongoDB","Sonar","SQL server","Specflow","Swagger","XUnit","Jira"]
            }
        },
        {
            id: 'MIS_cea',
            client: 'CEA',
            companyLogo: 'cea',
            city: "Mérignac",
            country: "France",
            startDate: new Date("2018-10-15T00:00:00.000+01:00"),
            endDate: new Date("2019-12-21T00:00:00.000+01:00"),
            collapseInfos: {
                description: 'Ergonomic and functional redesign of a website',
                technosSvg: ["html","css","javascript","php","zendframework"],
                technos: ["HTML","CSS","JavaScript","PHP","Zend Framework"]
            }
        }]
    }
}

const desouterExperience = {
    id: 'EXP_DesoutterTools',
    startDate: new Date("2018-02-26T00:00:00.000+01:00"),
    endDate: new Date("2018-08-24T00:00:00.000+01:00"),
    position: 'Intern',
    company: 'Desoutter Tools',
    companyLogo: 'desoutter',
    contract: 'Internship', 
    city: 'Nantes',
    country: 'France',
    collapseInfos: {
        description: 'Cross-platform application development',
        technosSvg:["xamarin","csharp","mvvm","balsamiq","sqllight","redmine","visualstudio"],
        technos:["Xamarin","C#","MVVM","Balsamiq","SQLite","Redmine","Visual Studio"]
    }
}

const greenwichExperience = {
    id: 'EXP_GreenwichUniversity',
    startDate: new Date("2017-05-29T00:00:00.000+01:00"),
    endDate: new Date("2017-08-25T00:00:00.000+01:00"),
    position: 'Intern',
    company: 'University of Greenwich',
    companyLogo: 'greenwich',
    contract: 'Internship', 
    city: 'Chatham',
    country: 'United Kingdom',
    collapseInfos: {
        description: 'Abroad experience and software configuration',
        technosSvg:["voicecode","dragon","latex"],
        technos:["VoiceCode","Dragon","LaTeX"]
    }
}

export const columnExperiences = [
    
    "Company",
    "Position",
    "Contract",
    "City",
    "Country",
    "Period"
]

export const columnExperiencesMobile = [
    
    "Company",
    "Position"
]

export const experiences = [
    cdiscountExperience,
    siiExperience,
    desouterExperience,
    greenwichExperience
];

export const technicalSkills = [
    {
        skill: 'REST Api',
        rate : 3
    },
    {
        skill: 'Microservices',
        rate : 3
    },
    {
        skill: 'C#',
        rate : 3
    }, 
    {
        skill: '.NET Core',
        rate : 3
    },     
    {
        skill: 'GIT',
        rate : 2
    },  
    {
        skill: 'MongoDb',
        rate : 2
    }, 
    {
        skill: 'Kafka',
        rate : 2
    }, 
    {
        skill: 'CICD',
        rate : 2
    },     
    {
        skill: 'SQL',
        rate : 2
    }, 
    {
        skill: 'Xamarin',
        rate : 1
    },
    {
        skill: 'React',
        rate : 1
    }, 
    {
        skill: 'Java',
        rate : 1
    }
];

export const softSkills = [
    {
        skill: 'Teamwork',
        color: 'red',
        rate : 2
    },
    {
        skill: 'Problem-solving',
        rate : 2.5
    },
    {
        skill: 'Adaptability',
        rate : 2.5
    }, 
    {
        skill: 'Critical thinking',
        rate : 3
    }, 
    {
        skill: 'Willingness to learn',
        rate : 4
    }, 
    {
        skill: 'Open-mindedness',
        rate : 3
    }
];