import {common} from '@material-ui/core/colors';
import {createTheme} from '@material-ui/core/styles';

const palette = {
    primary: {
        contrastText: common.white,
        dark: '#006699',
       // main: '#336699'
        main: '#006699'
        //main: '#3399CC'
        // dark: '#263748',
        // light: '#7DA3B5'
    },
    secondary: {
        contrastText: common.white,
        main: '#FF3300'
    //    main: '#CC3300'
    //    main: '#FF6633'
        //main: '#F27519',
        // main: '#F27519',
        // dark: '#F00900',
        // light: '#FF706B'
    },
    success: {
        contrastText: common.white,
        main: '#73CD1F',
    },
    // error: {
    //     contrastText: common.white,
    //     main: '#DE320A'
    // },
    background: {
        default: '#EEEEEE',
        paper:'#F2F5F8'
    },
    text: {
        //primary: '#1484B2',
        //secondary: '#F28638',
        primary: '#006699',
        secondary: '#F00900',
        tertiary: common.white
    },
    // action: {
    //     active: '#2fb0f3',
    //     disabledBackground: '#cad5e4',
    //     hover: '#2fb0f3',
    //     hoverOpacity: 0,
    //     selected: '#2fb0f3',
    // }
};


/**
 * [Theme](https://material-ui.com/customization/themes/) to customize Material-UI.
 * @module theme
 * @example
 * // with MuiThemeProvider
 * import { MuiThemeProvider } from '@material-ui/core/styles';
 * import theme from '../theme';
 *
 * export default () => (
 *  <MuiThemeProvider theme={theme}>
 *    ...
 *  </MuiThemeProvider>
 * )
 * @example
 * // with ThemeProvider
 * import { theme, ThemeProvider } from '../theme';
 *
 * export default () => (
 *  <ThemeProvider theme={theme}>
 *    ...
 *  </ThemeProvider>
 * )
 */

export const themes = {
        main : createTheme({
            palette,
            typography: {
                fontFamily: "'Roboto', 'Open Sans', 'sans-serif'",
                fontSize: 15,
                fontWeightLight: 300,
                fontWeightMedium: 500,
                fontWeightRegular: 400,
                // button: {
                //     color: palette.secondary.main,
                //     fontFamily: "'Roboto', 'Open Sans', 'sans-serif'",
                //     fontSize: '0.7143rem', // 10 px
                //     fontWeight: 500,
                //     letterSpacing: '0',
                //     lineHeight: 1.75,
                // },
                h1: {
                    fontSize: '2.043rem',
                    fontWeight: 500,
                    lineHeight: "4rem",
                    //letterSpacing: 1,
                    //marginBottom:25,


                    //lineHeight: '5rem',
                    color: palette.text.primary
                },
                h2: {
                    fontSize: '2.143rem',
                    fontWeight: 400,
                    padding:"5px 5px 5px 0px",
                    letterSpacing: '0',
                    color: palette.primary.contrastText
                },
                h3: {
                    fontSize: '0.843rem',
                    lineHeight: '1.2',
                    color: palette.primary.contrastText
                },

                // home page
                h4: {
                    fontSize: '2.243rem',
                    fontWeight: 500,
                    lineHeight: "5rem",
                    color: palette.primary.main
                },
                h5: {
                    fontSize: '1.843rem',
                    //fontWeight: 500,
                    lineHeight: "3rem",
                    color: palette.primary.main
                },
                // h4: {
                //     fontSize: '0.843rem',
                //     lineHeight: '1.2',
                //     color: palette.primary.contrastText,
                //     // '&:hover': {
                //     //     fontWeight: 'bold' 
                //     // }
                // },
                subtitle1: {
                    fontSize: '1.300rem',
                    marginLeft: 0,
                    fontWeight: "bold",
                    color: palette.primary.main
                },
                subtitle2: {

                },
                body1: {
                    fontSize: '1.200rem',
                    marginLeft: 0,
                    color: palette.primary.main
                },        
                body2: {
                    fontSize: '1.200rem',
                    marginLeft: 0,
                    color: palette.primary.contrastText
                    // fontSize: '0.943rem',
                    // lineHeight: '2.5',
                    // color: palette.primary.contrastText
                },
                caption: {
                    color: common.white,
                    fontSize: '1.000rem'
                }

            },
            prop: {     
                // MuiTypography: {
                //     variantMapping: {
                //       h1: 'h1',
                //       h2: 'h2',
                //       h3: 'h3',
                //       h4: 'h4',
                //       h5: 'h5',
                //       h6: 'h6',
                //       subtitle1: 'span',
                //       subtitle2: 'span',
                //       body1: 'h6',
                //       body2: 'h6',
                //     },
                // }
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: palette.background,
                        //padding: '6px 16px'
                    }
                },
                MuiCard: {
                    root: {
                        //backgroundColor: palette.background,
                        //boxShadow: 10,
                        
                        borderRadius: 10,
                        //border: '0.1em solid',
                        //borderColor: palette.primary.main
                    }
                },
                // MuiButton: {
                //     root: {
                //         lineHeight: 3,
                //         backgroundColor: palette.primary.main,
                //         borderRadius: 50,
                //         boxShadow: 'none',
                //         color: palette.primary.contrastText,
                //         fontSize: '1rem', // 16px
                //         margin: "auto",
                //         border: '0.15rem solid',
                //         '&:hover': {
                //             color: palette.primary.main,
                //             borderColor: palette.secondary.main,
                //             backgroundColor: palette.primary.contrastText,
                //             boxShadow: 'none',
                //         }
                //     },
                //     textPrimary: {
                //         color: palette.primary.contrastText,
                //         '&:hover': {
                //             backgroundColor: palette.primary.contrastText,
                //             color: palette.primary.main,
                //             borderColor: palette.secondary.main
                //         }
                //     }
                // },
                // MuiTextField: {
                //     root:
                //     {
                //         '& label.Mui-focused': {
                //             color: palette.primary.contrastText
                //         },
                //         '& .MuiOutlinedInput-root':
                //         {
                //             '& fieldset': {
                //                 borderColor: palette.secondary.main,
                //             },
                //             '&:hover fieldset': {
                //                 borderColor: palette.primary.contrastText,
                //             },
                //             '&.Mui-focused fieldset': {
                //                 borderColor: palette.primary.contrastText,
                //             }
                //         }
                //     }
                // },
                // MuiPickersToolbarButton: {
                //     toolbarBtn: {
                //         borderStyle: "none",
                //         '&:hover': {
                //             backgroundColor: palette.primary.main,
                //             cursor: "default"
                //         }
                //     }
                // },
                // MuiPickersYear: {
                //     root: {
                //         color: palette.primary.contrastText,
                //         '&:hover': {
                //             color: palette.secondary.main,
                //             backgroundColor: palette.primary.contrastText
                //         }
                //     }
                // },
                // MuiPickersCalendarHeader: {
                //     iconButton: {
                //         '&:hover': {
                //             backgroundColor: palette.secondary.main,
                //             color: palette.primary.contrastText
                //         }
                //     },
                //     dayLabel: {
                //         color: palette.secondary.main
                //     }
                // },
                // MuiPickersBasePicker: {
                //     pickerView: {
                //         backgroundColor: palette.primary.main
                //     }
                // },
                // MuiPickersDay: {
                //     day: {
                //         color: palette.primary.contrastText,
                //         // fontFamily: "\"Do Hyeon\", sans-serif",
                //         // backgroundColor: "white",
                //         // borderRadius: "0px",
                //         '&:hover': {
                //             backgroundColor: palette.primary.contrastText,
                //             color: palette.primary.main
                //         }
                //     },
                //     container: {
                //         backgroundColor: "black"
                //     },
                //     daySelected: {
                //         backgroundColor: palette.primary.contrastText,
                //         color: palette.secondary.main,
                //         '&:hover': {
                //             backgroundColor: palette.primary.contrastText,
                //             color: palette.primary.main
                //         }
                //     },
                //     dayDisabled: {
                //         color: "black",
                //     },
                //     current: {
                //     },
                // },
                // MuiDialogActions: {
                //     root: {
                //         backgroundColor: palette.primary.main
                //     }
                // },
                MuiTab: {
                    root: {
                        //witdh: '100%',
                        //color: palette.primary.main,
                        //backgroundColor: palette.secondary.main,
                        fontWeight: 400,
                        '&:hover': {
                            fontWeight: 500,
                        },
                    },
                    textColorInherit: {
                        opacity: 1,
                    },
                },
                MuiTabs: {
                    root: {
                        //width: '100%'
                        // boxShadow: 'none',
                        // //color: palette.primary.main,
                        // '&:active': {
                        //     background: palette.secondary.main,
                        //     color: palette.primary.main,
                        // }
                    },
                    flexContainer : {
                        height: '100%',
                        //overflow: 'scroll'
                    }
                },
                MuiListItemText:{
                    root:{
                        marginTop: 0
                    }
                }
                // MuiTimelineOppositeContent:{
                //     root:{
                //         //padding: "0px 0px 0px 0px",
                //         margin:0,
                //         flex: 0.12
                //     }
                // },
                // MuiDrawer:{
                //     paper:{
                //         top: 'auto',
                //         zIndex: 0,
                //         flex: '1 0 auto',
                //         height: '100%',
                //         display: 'flex',
                //         outline: 0,
                //         position: 'fixed',
                //         overflowY: 'auto',
                //         flexDirection: 'column'
                //     }
                // }
            },
        }),
        second: createTheme({

        })
    };

const theme = themes.main;

export default theme;
