import styled from 'styled-components';
import {alpha, Typography} from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
    padding:"20px 35px 90px 35px"
}));

export const SkillsContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "spaceBetween",
    alignItems: "stretch",
    paddingTop: "15px"
}));

export const SkillItem = styled('div')(({ theme, bgColor }) => ({
    borderRadius: "5px",
    margin: "10px 10px",
    padding: "5px",
    backgroundColor: alpha(theme.palette.primary.contrastText, 0.2),
    flex: "1 1 0px"
}));


export const SkillItemOld = styled('div')`
    border-radius: 5px;
    background-color: ${props => alpha(props.theme.palette.primary.contrastText, 0.2)};
    flex: 1 1 0px;
    margin: 10px 10px;
    :first-of-type {
        margin-left: 0px;
    }
    :last-of-type {
        margin-right: 0px;
    }
`

export const SkillTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText
}));

export const DateTypography = styled(Typography)(({ theme }) => ({

}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
    marginTop: '1rem'
}));