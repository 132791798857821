const WORDS = [
    "ALEXIS",
    "PORTFOLIO",
    "MARVEL",
    "SOFTWARE",
    "ENGINEER",
    "HANGMAN",
    "EXCELSIOR",
    "MOVIES",
    "SATURNE",
    "VENUS",
    "MARS",
    "NEPTUNE",
    "SMARTPHONE",
    "COMPUTER",
    "OVERWATCH",
    "HOGWARTS",
    "ANDROID",
    "APPLE",
    "MUSIC",
    "TIRAMISU"
]

export default WORDS