import {Card, Grid, Typography} from '@material-ui/core';
import {Parallax} from 'react-scroll-parallax';
import styled from 'styled-components';
import {FadeIn, FadeInWithXTranslation, FadeInWithYTranslation} from '../../keyframes/styles';
import {LogoSpin} from '../../keyframes/styles.js'
import {headerWithTabsHeight} from '../mainPage/styles';
import {footerHeight} from "../../components/Footer/styles";


export const slideHeight = `calc(100vh - ${headerWithTabsHeight})`

export const Container = styled.div`
    maxWidth: '100%';
    animation: ${FadeIn} 1000ms cubic-bezier(0.47, 0, 0.745, 0.715);
`
export const StyledSpanFirstLetter = styled('span')(({ theme }) => ({
    color: theme.palette.secondary.main
}));

export const StyledSpanOtherLetters = styled('span')(({ theme }) => ({

}));

export const Bold = styled('span')(({ theme }) => ({
    fontWeight: 'bold'
}));

export const StyledParallax = styled(Parallax)(({ theme }) => ({

}));

export const IntroductionDescription = styled.div`
    animation: ${FadeInWithYTranslation} 1500ms cubic-bezier(0.47, 0, 0.745, 0.715);
    max-width: 40rem;
`

export const IntroductionDescriptionTypography = styled(Typography)(({theme}) => ({
    paddingBottom: '1rem'
}))

export const IntroductionTextContainer = styled('div')(({theme}) => ({
    // display: "flex",
    // flexDirection: "column",
    // justifyContent : "flex-start",
    // alignItems: "stretch",
    // paddingLeft: "3rem",
    // paddingBottom: '5rem'
}));

export const IntroductionTitle = styled.div`
    padding-bottom: 2rem;
`;

export const IconsGridContainer = styled(Grid)(({ theme }) => ({
   // paddingTop: "1rem"
}));

export const SlideContainer1 = styled.div`
    animation: ${FadeIn} 1000ms cubic-bezier(0.47, 0, 0.745, 0.715);
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: ${slideHeight};
`

export const StyledParallaxProfile = styled(Parallax)`
    margin: 0;
    animation: ${FadeInWithXTranslation} 1500ms cubic-bezier(0.47, 0, 0.745, 0.715);
`

export const ReactAnimatedImg = styled.img`
   animation: ${LogoSpin} infinite 20s linear;
   height: 15rem;
   margin-top: 4rem;
   `

export const SlideContainer2 = styled.div`
   box-sizing: border-box;
   overflow: hidden;
   // width: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   padding-left: 40px;
   padding-right: 40px;
   min-height: ${slideHeight};
`

export const SlideContainer3 = styled.div`
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 40px;
    padding-right: 40px;
    min-height: ${slideHeight};
`

export const ParallaxContainerV2 = styled('div')(({ theme }) => ({

}));

export const SlideContainer4 = styled.div`
   box-sizing: border-box;
   overflow: hidden;
   padding-left: 40px;
   padding-right: 40px;
   min-height: ${slideHeight};
`

export const SlideContainer5 = styled.div`
   box-sizing: border-box;
   overflow: hidden;
   padding-left: 40px;
   padding-right: 40px;
   padding-top: 40px;
   margin:0;
   padding-bottom: calc(${footerHeight} + 0.5 * ${footerHeight});
   min-height: ${slideHeight};
`

export const StyledBottomCardV2 = styled(Card)(({ theme }) => ({
    // height:'50%',
    //width: "fit-content",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto"
}));
