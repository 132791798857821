import React, {Component} from 'react';
import {
    BackgroundImage,
    ButtonMarked,
    ButtonTitle,
    ButtonTypography,
    GamesContainer,
    ImageBackdrop,
    StyledButtonBase
} from './styles';
import gameList from './gameList'

import {Link} from 'react-router-dom'
import { Grid } from '@material-ui/core';


class Games extends Component
{
    render()
    {
        const isMobile = window.mobileCheck()
        return (
            <GamesContainer 
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                    spacing={5}
                    >
                {
                    gameList.map((game) => 
                    <Grid 
                        key={game.title}
                        item 
                        xs={ (isMobile) ? 12 : 6} 
                        >
                        <Link 
                        key={game.title}
                        to={`${this.props.location.pathname}${game.link}`}>
                            <StyledButtonBase
                                ismobile={isMobile.toString()}
                                >
                                <BackgroundImage
                                    style={{
                                        backgroundImage: `url(${game.imageSrc})`,
                                    }}
                                />
                                <ImageBackdrop/>
                                <ButtonTitle>
                                    <ButtonTypography
                                    component="span"
                                    variant="h2"
                                    >
                                    {game.title}
                                    <ButtonMarked />
                                    </ButtonTypography>
                                </ButtonTitle>
                            </StyledButtonBase>
                        </Link>
                    </Grid>
                    )
                }
                </Grid>
            </GamesContainer>
        )
    }
}

export default Games