import {mdiBriefcase, mdiFlaskOutline, mdiGamepadVariant, mdiSchool} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

const data = [
    {
        id: 'TAB_education',
        icon: <Icon size={1} path={mdiSchool} style={{ boxSizing: 'border-box', marginBottom: '10px' }} />,
        label: 'Education',
        link: '/education'
    },
    {
        id: 'TAB_experiences',
        icon: <Icon size={1} path={mdiBriefcase} style={{ boxSizing: 'border-box', marginBottom: '10px' }} />,
        label: 'Professional experience',
        link: '/experiences'
    },
    {
        id: 'TAB_projects',
        icon: <Icon size={1} path={mdiFlaskOutline} style={{ boxSizing: 'border-box', marginBottom: '10px' }} />,
        label: 'Personal projects',
        link: '/projects'
    },
    {
        id: 'TAB_games',
        icon: <Icon size={1} path={mdiGamepadVariant} style={{ boxSizing: 'border-box', marginBottom: '10px' }} />,
        label: 'Games',
        link: '/games'
    }
];

export default data;
