import React from 'react';
import AdbRoundedIcon from '@material-ui/icons/AdbRounded'
import AirplanemodeActiveRoundedIcon from '@material-ui/icons/AirplanemodeActiveRounded'
import AudiotrackRoundedIcon from '@material-ui/icons/AudiotrackRounded'
import AssistantPhotoRoundedIcon from '@material-ui/icons/AssistantPhotoRounded'
import BeachAccessRoundedIcon from '@material-ui/icons/BeachAccessRounded'
import BrushRoundedIcon from '@material-ui/icons/BrushRounded'
import BugReportRoundedIcon from '@material-ui/icons/BugReportRounded'
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded'
import ChildFriendlyRoundedIcon from '@material-ui/icons/ChildFriendlyRounded'
import DirectionsBikeRoundedIcon from '@material-ui/icons/DirectionsBikeRounded'
import DirectionsCarRoundedIcon from '@material-ui/icons/DirectionsCarRounded'
import DirectionsWalkRoundedIcon from '@material-ui/icons/DirectionsWalkRounded'
import FilterVintageRoundedIcon from '@material-ui/icons/FilterVintageRounded'
import SportsEsportsRoundedIcon from '@material-ui/icons/SportsEsportsRounded'
import SportsTennisRoundedIcon from '@material-ui/icons/SportsTennisRounded'
import EmojiObjectsRoundedIcon from '@material-ui/icons/EmojiObjectsRounded'
import LocalBarRoundedIcon from '@material-ui/icons/LocalBarRounded'
import PetsRoundedIcon from '@material-ui/icons/PetsRounded'

const iconStyle = { width: '100%', fontSize: '30'}

const ICONS = [
    {
        label: 'android',
        icon: <AdbRoundedIcon htmlColor="#a4c639" style= { iconStyle } />
    },   
    {
        label: 'airplane',
        icon: <AirplanemodeActiveRoundedIcon htmlColor="#808080" style= { iconStyle } />
    },   
    {
        label: 'lightobject',
        icon: <EmojiObjectsRoundedIcon htmlColor="#FF0000" style= { iconStyle } />
    },   
    {
        label: 'audiotrack',
        icon: <AudiotrackRoundedIcon htmlColor="black" style= { iconStyle } />
    },   
    {
        label: 'photo',
        icon: <AssistantPhotoRoundedIcon htmlColor="#77b5fe" style= { iconStyle } />
    },   
    {
        label: 'beach',
        icon: <BeachAccessRoundedIcon htmlColor="#FFC13B" style= { iconStyle } />
    },   
    {
        label: 'bar',
        icon: <LocalBarRoundedIcon htmlColor="#FF7F7F" style= { iconStyle } />
    },   
    {
        label: 'brush',
        icon: <BrushRoundedIcon htmlColor="#8B6C42" style= { iconStyle } />
    },   
    {
        label: 'bug',
        icon: <BugReportRoundedIcon htmlColor="#D8240A" style= { iconStyle } />
    },   
    {
        label: 'business',
        icon: <BusinessCenterRoundedIcon htmlColor="#2DA39C" style= { iconStyle } />
    },   
    {
        label: 'friendly',
        icon: <ChildFriendlyRoundedIcon htmlColor="#FD6C9E" style= { iconStyle } />
    },   
    {
        label: 'bike',
        icon: <DirectionsBikeRoundedIcon htmlColor="#0F4C81" style= { iconStyle } />
    },   
    {
        label: 'car',
        icon: <DirectionsCarRoundedIcon htmlColor="#BB0B0B" style= { iconStyle } />
    },   
    {
        label: 'walk',
        icon: <DirectionsWalkRoundedIcon htmlColor="#1691D9" style= { iconStyle } />
    },   
    {
        label: 'pets',
        icon: <PetsRoundedIcon htmlColor="#4B8B3B" style= { iconStyle } />
    },   
    {
        label: 'esports',
        icon: <SportsEsportsRoundedIcon htmlColor="#2C75FF" style= { iconStyle } />
    },   
    {
        label: 'vintage',
        icon: <FilterVintageRoundedIcon htmlColor="#063971" style= { iconStyle } />
    },   
    {
        label: 'tennis',
        icon: <SportsTennisRoundedIcon htmlColor="#7F007F" style= { iconStyle } />
    }
]

export default ICONS