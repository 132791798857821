import {alpha, Grid, styled, Table, TableCell, Typography} from "@material-ui/core";


export const CollapseContainer = styled('div')(({ theme }) => ({
    
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
    width: 'fit-content',
    margin: '0 auto',
    borderRadius: "5px",
    paddingBottom: '2rem',
    fontStyle:'italic',
    //backgroundColor: alpha(theme.palette.secondary.main, 0.5),
}));

export const TechnoTypography = styled(Typography)(({ theme }) => ({
    borderRadius: "5px",
    //margin: "10px 10px",
    padding: "5px",
    overflow: "unset",
    backgroundColor: alpha(theme.palette.primary.main, 1)
}));

export const StyledTechnoGrid = styled(Grid)(({ theme }) => ({
    //borderRadius: "5px",
    //backgroundColor: alpha(theme.palette.primary.main, 1)
}));

export const LogoContainer = styled('div')(({ theme }) => ({
    margin:10
}));

export const StyledTable = styled(Table)(({theme}) => ({

}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    color: theme.palette.primary.main
}));