import React, {Component} from 'react'
import {educations} from './data'
import {Container, DateTypography, DescriptionTypography, SkillItem, SkillsContainer, SkillTypography} from './styles'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'
import './verticalTimeline.css'
import {Typography, withTheme} from '@material-ui/core'

class Education extends Component
{
    state = {
        isSmallScreen : this.isSmallScreen()
    }

    handleResize = () => {
        this.setState({ isSmallScreen : this.isSmallScreen()})
    }; 

    isSmallScreen() {
        return window.innerWidth <= 1000
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleResize);
    }

    render()
    {
        const { isSmallScreen } = this.state
        const isMobile = window.mobileCheck()
        const educationsCloned = Array.from(educations)
        return (
            <Container>
                <VerticalTimeline animate={!isMobile} 
                style={ `background-color: ${this.props.theme.palette.primary.main};` }>
                    {
                        educationsCloned.reverse().map((formation, index) => 
                        {
                            return(
                                <VerticalTimelineElement
                                    key={formation.title}
                                    contentStyle={{ background: formation.backgroundColor, color:  this.props.theme.palette.primary.main }}
                                    contentArrowStyle={{ borderRight: `7px solid  ${formation.backgroundColor}` }}
                                    date= {
                                        isMobile || isSmallScreen?
                                        <DateTypography variant="body2">{formation.date}</DateTypography>
                                        :
                                        <DateTypography variant="body1">{formation.date}</DateTypography>
                                    }
                                    iconStyle={{ background: formation.backgroundColor, color: this.props.theme.palette.primary.contrastText }}
                                    icon={formation.icon}>
                                        <Typography variant="h2" style={{ fontWeight: 'bold' }}>
                                            {formation.title}
                                        </Typography>
                                        <DescriptionTypography variant="body2">
                                            {formation.description}
                                        </DescriptionTypography>
                                        <SkillsContainer>
                                            {
                                                formation.skills.map((skill) => {
                                                    return (
                                                        <SkillItem key={skill}
                                                        >
                                                            <SkillTypography variant="subtitle2"
                                                            align="center"
                                                            noWrap={true}
                                                            >
                                                                {skill}
                                                            </SkillTypography>
                                                        </SkillItem>
                                                )}) 
                                            }
                                        </SkillsContainer>
                                </VerticalTimelineElement>
                            )
                        })
                    }
                </VerticalTimeline>
            </Container>
        );
    }
}
  
  
export default withTheme(Education)