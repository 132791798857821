import styled from 'styled-components';

export const headerHeight = '4rem'

export const Container = styled('div')(({ theme }) => ({  
    backgroundColor: theme.palette.primary.dark,
    height: headerHeight,
    display: 'flex',
    alignItems: 'center'
}
));

export const StyledSpanFirstLetter = styled('span')(({ theme }) => ({
    color: theme.palette.secondary.main,
    paddingLeft: "35px"
}));
