import styled from 'styled-components';
import {Grid, Tab, Tabs} from '@material-ui/core';
import {headerHeight} from '../../components/Header/styles';

export const tabsHeight = '5rem'

export const headerWithTabsHeight = `calc(${tabsHeight} + ${headerHeight})`;

export const Container = styled('div')(({ theme }) => ({
    
}));

export const TabContent = styled('div')(({ theme }) => ({
    height: '100%'
}));

export const ScrolledGrid = styled(Grid) (({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    position: "sticky",
    zIndex:"1",
    top: 0, 
    width: "100%",
    height: tabsHeight
}));

export const GridItem = styled(Grid) (({ theme }) => ({
    height: "100%"
}));

export const StyledTabs = styled(Tabs)(({ theme }) =>({
    height: "100%"
}));

export const StyledTab = styled(Tab)(({ selected, theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
    },
    textDecoration: 'none',
    '& svg': {
        marginTop: 10,
    },
    color: theme.palette.primary.contrastText,
    fontWeight: selected ? 700: 0,
    backgroundColor: selected ? theme.palette.secondary.main: theme.palette.primary.dark,

    height: "100%"
}));

