import React, {Component} from 'react';
import {CardContainer, MemoryCard, MemoryContainer, ScoreContainer, StyledSnackbar, Symbol} from './styles'

import shuffleArray from '../../../helpers/ShuffleHelper'
import {Grid, Typography} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import HelpIcon from '@material-ui/icons/Help'
import ICONS from './icons'


const SIDE = 6

const VISUAL_PAUSE_MSECS = 750
const HIGH_SCORE_KEY = 'Games::Memory::HighScore'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Memory extends Component
{
    state = {
        displayWonMessage: false,
        cards: this.generateCards(),
        currentPair: [],
        guesses: 0,
        matchedCardIndexes: [],
        highScore : localStorage.getItem(HIGH_SCORE_KEY)
    }

    restart = () =>
    {
        this.setState(
            {
                displayWonMessage: false,
                cards: this.generateCards(),
                currentPair: [],
                guesses: 0,
                matchedCardIndexes: [],
                highScore : localStorage.getItem(HIGH_SCORE_KEY)
            }
        )
    }

    generateCards()
    {
        let symbols = ICONS.slice()
        const result = []
        const size = SIDE * SIDE

        const candidates = shuffleArray(symbols)

        while (result.length < size)
        {
            const card = candidates.pop()
            result.push(card, card)
        }

        return shuffleArray(result)
    }

    handleCardClick = index =>
    {
        const { cards, currentPair, guesses, matchedCardIndexes, highScore } = this.state

        if (currentPair.length === 2)
        {
            return
        }

        if (currentPair.length === 0)
        {
            this.setState({ currentPair: [index] })
            return
        }
        
        if(currentPair[0] === index){
            return
        }
        
        if(matchedCardIndexes.includes(index)){
            return
        }

        const newPair = [currentPair[0], index]
        const newGuesses = guesses + 1
        
        const matched = cards[newPair[0]].label === cards[newPair[1]].label
        this.setState({ currentPair: newPair, guesses: newGuesses })
        if (matched)
        {
            this.setState({ matchedCardIndexes: [...matchedCardIndexes, ...newPair] })

            const won = matchedCardIndexes.length + 2 === cards.length
            
            if(won){
                if(highScore === null || guesses < highScore){
                    this.setState ( { highScore : newGuesses } )
                    localStorage.setItem(HIGH_SCORE_KEY, JSON.stringify(newGuesses))
                }
                this.setState ( { displayWonMessage : won} )
            }
    
        }
        setTimeout(() => this.setState({ currentPair: [] }), VISUAL_PAUSE_MSECS)
    }

    getCardState(index)
    {
      const { currentPair, matchedCardIndexes } = this.state
      const indexHasBeenMatched = matchedCardIndexes.includes(index)
      
      if (currentPair.length < 2)
      {
        return indexHasBeenMatched || index === currentPair[0] ? 'visible' : 'hidden'
      }
  
      if (currentPair.includes(index))
      {
        return indexHasBeenMatched ? 'justMatched' : 'justMismatched'
      }
  
      return indexHasBeenMatched ? 'visible' : 'hidden'
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({ displayWonMessage: false })
      };

    render()
    {
        const { cards, guesses, highScore, displayWonMessage } = this.state
        const newHighScore = highScore === guesses;
        let isMobile = window.mobileCheck()
        return (
          <MemoryContainer>
            <Grid 
                container
                direction="column"
                alignItems="flex-end"
                justifyContent="flex-start"
            >
                <Grid item>
                    <ScoreContainer>
                        {
                            highScore !== null && 
                            <Typography variant="subtitle1" align="right">
                                High score : {highScore}
                            </Typography>
                        }
                        <Typography variant="subtitle1"  align="right">
                            Move : {guesses}
                        </Typography>
                    </ScoreContainer>
                </Grid>
            </Grid>

            <CardContainer ismobile= {isMobile}>
                {cards.map((card, index) => (
                <MemoryCard
                    cardstate={this.getCardState(index)}
                    index={index}
                    key={index}
                    onClick={() => this.handleCardClick(index)}
                >
                    <Symbol align="center">
                        {this.getCardState(index) === 'hidden' ?  <HelpIcon htmlColor="white" fontSize="medium" style={{ width: '100%', fontSize: '30'}} /> : card.icon }
                    </Symbol>
                </MemoryCard>
                ))}
            </CardContainer>
            {
                <StyledSnackbar 
                    open={displayWonMessage} 
                    >
                    <Alert severity="success" onClose={this.handleClose} onClick={() => this.restart()}>
                        Bravo ! { newHighScore && " New high score !"} Click here to restart !
                    </Alert>
                </StyledSnackbar>
            }
          </MemoryContainer>
        )
    }
}

export default Memory