import styled from 'styled-components'
import {Card, Snackbar, Typography} from '@material-ui/core';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import { footerHeight } from '../../../components/Footer/styles';

export const MemoryContainer = styled('div')(({ theme }) => ({
    padding: `20px 35px calc( 20px + ${footerHeight} ) 35px`,
    maxWidth :'1400px',
    margin:'auto'
}));

export const ScoreContainer = styled(Card)(({ theme }) => ({
    float: "right",
    padding:"10px",
    backgroundColor:alpha(theme.palette.primary.main, 0.3)
}));

export const CardContainer = styled('div')(({ theme, ismobile }) => ({
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "borderBox",
    userSelect: "none",
    width: ismobile? '100%': '80%',
    paddingTop:'1.5rem',
    margin:'auto'
}));

export const Symbol = styled('div')(({ theme }) => ({
    margin:'auto',
    width:"100%",
    //backgroundColor: theme.palette.primary.contrastText //alpha(theme.palette.primary.main, 0.3)
}));

export const MemoryCard = styled(Card)(({ theme, cardstate }) => ({
    fontSize: "2.5rem",
    flex: "1 1 calc(100% / 6 - 20px)",
    margin:"10px",
    cursor: "pointer",
    background: cardstate === 'hidden' ? `${theme.palette.primary.main}` : `${alpha(theme.palette.primary.main, 0.1)}`,
    boxShadow: 
        cardstate === 'justMismatched' ? 
            "0em 0em 0em 0.1em red"  
        : cardstate === 'justMatched' ? 
            "0em 0em 0em 0.1em green" 
        : `0em 0em 0em 0.1em ${theme.palette.primary.main}`,
}));

export const WonContainer = styled(Card)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.success.main, 0.3),
    display:"flex"
}));

export const WonTypography = styled(Typography)(({ theme }) => ({
    lineHeight:3,
    flex:"1"
}));

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    marginBottom:"4rem",
    cursor: "pointer"
}));