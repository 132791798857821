import React, {Component} from 'react'
import {Container} from './styles'
import {Typography} from '@material-ui/core'

class Polyjoule extends Component
{
    render()
    { 
        return (
            <Container>
                <Typography paragraph={true}>
                    Privacy policy
                </Typography>

                <Typography paragraph={true}>
                    Last updated april 27th, 2022
                </Typography>

                <Typography paragraph={true}>
                    INTRODUCTION 
                </Typography>

                <Typography paragraph={true}>
                    Alexis Priou built the Polyjoule app as a Free app. This SERVICE is provided by Alexis Priou at no cost and is intended for use as is.
                </Typography>

                <Typography paragraph={true}>
                    Polyjoule respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Polyjoule”). 
                </Typography>

                <Typography paragraph={true}>
                    Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the application. 
                </Typography>

                <Typography paragraph={true}>
                    We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. 
                </Typography>

                <Typography paragraph={true}>
                    You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.  
                </Typography>

                <Typography paragraph={true}>
                    This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party. 
                </Typography>

                <Typography paragraph={true}>
                    COLLECTION OF YOUR INFORMATION
                </Typography>

                <Typography paragraph={true}>
                    We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:  
                </Typography>

                <Typography paragraph={true}>
                    Personal Data 
                </Typography>
                <Typography paragraph={true}>
                    Demographic and other personally identifiable information (such as your email address) that you voluntarily give to us when choosing to participate in various activities related to the Application, such as participate in the ranking of our game. If you choose to share data about yourself via your profile please be advised that your pseudo is public and will be accessible to anyone who accesses the Application.
                </Typography>

                <Typography paragraph={true}>
                    Mobile Device Access 
                </Typography>

                <Typography paragraph={true}>
                    We may request access or permission to certain features from your mobile device, including your mobile device’s internet and other features. If you wish to change our access or permissions, you may do so in your device’s settings.
                </Typography>

                <Typography paragraph={true}>
                    Push Notifications 
                </Typography>
                <Typography paragraph={true}>
                    We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                </Typography>

                <Typography paragraph={true}>
                    Data From Contests, Giveaways, and Surveys 
                </Typography>
                <Typography paragraph={true}>
                    Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
                </Typography>

                <Typography paragraph={true}>
                    USE OF YOUR INFORMATION
                </Typography>
                <Typography paragraph={true}>
                    Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to: 

                        <Typography paragraph={true}>
                            •	Administer sweepstakes, promotions, and contests. 
                        </Typography>
                        <Typography paragraph={true}>
                            •	Create and manage your account.
                        </Typography>
                        <Typography paragraph={true}>
                            •	Monitor and analyze usage and trends to improve your experience with the Application.
                        </Typography>
                        <Typography paragraph={true}>
                            •	Notify you of updates to the Application.
                        </Typography>
                        <Typography paragraph={true}>
                            •	Resolve disputes and troubleshoot problems.
                        </Typography>
                        <Typography paragraph={true}>
                            •	Respond to product and customer service requests.
                        </Typography>
                </Typography>

                <Typography paragraph={true}>
                    DISCLOSURE OF YOUR INFORMATION
                </Typography>

                <Typography paragraph={true}>
                    We may share information we have collected about you in certain situations. Your information may be disclosed as follows: 
                </Typography>

                <Typography paragraph={true}>
                    By Law or to Protect Rights 
                </Typography>
                <Typography paragraph={true}>
                    If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
                </Typography>

                <Typography paragraph={true}>
                    Third-Party Service Providers 
                </Typography>
                <Typography paragraph={true}>
                    We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.  
                </Typography>

                <Typography paragraph={true}>
                    We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly. 
                </Typography>

                <Typography paragraph={true}>
                    SECURITY OF YOUR INFORMATION
                </Typography>
                <Typography paragraph={true}>
                    We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
                </Typography>

                <Typography paragraph={true}>
                    POLICY FOR CHILDREN
                </Typography>
                <Typography paragraph={true}>
                    We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below. 
                </Typography>

                <Typography paragraph={true}>
                    CONTROLS FOR DO-NOT-TRACK FEATURES  
                </Typography>

                <Typography paragraph={true}>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.  
                </Typography>
                
                <Typography paragraph={true}>
                    OPTIONS REGARDING YOUR INFORMATION
                </Typography>

                <Typography paragraph={true}>
                    [Account Information]
                </Typography>
                <Typography paragraph={true}>
                    You may at any time review or change the information in your account or terminate your account by:
                        •	Logging into your account settings and updating your account
                        •	Contacting us using the contact information provided below
                </Typography>

                <Typography paragraph={true}>
                    Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.]
                </Typography>

                <Typography paragraph={true}>
                    CALIFORNIA PRIVACY RIGHTS
                </Typography>

                <Typography paragraph={true}>
                    California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                </Typography>

                <Typography paragraph={true}>
                    If you are under 18 years of age, reside in California, and have a registered account with the Application, you have the right to request removal of unwanted data that you publicly post on the Application. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Application, but please be aware that the data may not be completely or comprehensively removed from our systems.
                </Typography>
                    
                <Typography paragraph={true}>
                    CONTACT US
                </Typography>

                <Typography paragraph={true}>
                    If you have questions or comments about this Privacy Policy, please contact us at:
                </Typography>
                <Typography paragraph={true}>
                    https://alexispriou.com/contact
                </Typography>
            </Container>
        );
    }
}

export default Polyjoule;