import styled from 'styled-components';

export const Root = styled('div')(({ theme }) => ({
    userSelect: "none",
    backgroundColor: theme.palette.background.default
}));

export const Container = styled('div')(({theme}) => ({
    position: "relative",
    minHeight: "100vh"
}))