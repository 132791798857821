import {Card, Grid} from '@material-ui/core';
import styled from 'styled-components';
import { footerHeight } from '../../../components/Footer/styles';

export const ProjectContainer = styled('div')(({ theme }) => ({  
    boxSizing: "border-box",
    padding: `20px 35px calc(${footerHeight} + ${footerHeight}) 35px`,
    // height: "100%",
    // overflow: "scroll"
}));

export const ScreensContainer = styled('div')(({theme}) => ({
    paddingTop:"2rem"
}));

export const StyledGrid = styled(Grid)(({theme}) => ({
    
}));

export const StyledCard = styled(Card)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText
}))