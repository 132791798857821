import formation from './education'
import experiences from './experiences'
import projects from './projects'
import games from './games'
import memory from './memory'
import hangman from './hangman'
import homepage from './homepage'
import polyjoule from './polyjoule'
import wifistate from './wifistate'
import contact from './contact'
import unfound from './unfound'

export default [
    ...homepage,
    ...formation,
    ...experiences,
    ...projects,
    ...games,
    ...memory,
    ...hangman,
    ...polyjoule,
    ...wifistate,
    ...contact,
    ...unfound
];
