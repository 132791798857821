import {mdiBookOpenPageVariant, mdiCheckDecagram, mdiLaptop, mdiNeedle, mdiSchool} from '@mdi/js';
import {Icon} from '@mdi/react';
import React from 'react';

// export const formations_FR = [
//     {
//         id: 'DEG_ingineer',
//         date: '2018',
//         title: 'DIPLÔME D\'INGÉNIEUR',
//         icon: <Icon size={1} path={mdiCheckDecagram} />,
//         description: 'Electronic and Digital Technologies',
//         backgroundColor: '#FFD700',
//         skills: ["test"]
//     },
//     {
//         id: 'FOR_ingeneer',
//         date: '2015 - 2018',
//         title: 'Polytech Nantes',
//         icon: <Icon size={1} path={mdiLaptop} />,
//         description: 'Electronic and Digital Technologies',
//         backgroundColor: '#004777',
//         skills: ["Digital Technologies", 
//             // "Electronics", 
//             // "Industrial Data Processing", 
//             "Embedded Systems", "Telecommunications", "Networks" , "Multimedia"]
//     },
//     {
//         id: 'FOR_peip',
//         date: '2013 - 2015',
//         title: 'P.E.I.P',
//         icon: <Icon size={1} path={mdiBookOpenPageVariant} />,
//         description: 'Préparation aux écoles d\'ingénieurs Polytech',
//         backgroundColor: '#009CDF',
//         skills: [ "Mathematics", "Physics", "Chemistry", "IT and digital", "Engineering culture" ]
//     },
//     {
//         id: 'FOR_medecine',
//         date: '2012 - 2013',
//         title: 'P.A.C.E.S',
//         icon: <Icon size={1} path={mdiNeedle} />,
//         description: 'Première année commune aux études de santés',
//         backgroundColor: '#2DA39C',
//         skills: [ "Self discipline", "Organization" ]
//     },
//     // {
//     //     id: 'FOR_bac',
//     //     date: '2013',
//     //     title: 'BACCALAURÉAT',
//     //     icon: <Icon size={1} path={mdiCheckDecagram} />,
//     //     description: 'Mention bien',
//     //     backgroundColor: '#FFD700'
    
//     // },
//     {
//         id: 'FOR_lycee',
//         date: '2009 - 2012',
//         title: 'Lycée de Pornic',
//         icon: <Icon size={1} path={mdiSchool} />,
//         description: 'Spécialité S, SVT',
//         backgroundColor: '#662F9D',
//         skills: [ "Diploma in Science with mention" ]
//     }
// ];

export const educations = [
    {
        id: 'DEG_ingineer',
        date: '2018',
        title: 'ENGINEERING DEGREE',
        icon: <Icon size={1} path={mdiCheckDecagram} />,
        description: 'Electronic and Digital Technologies',
        backgroundColor: '#FFD700',
        skills: []
    },
    {
        id: 'FOR_ingeneer',
        date: '2015 - 2018',
        title: 'Polytech Nantes',
        icon: <Icon size={1} path={mdiLaptop} />,
        description: 'Three years in a french graduate engineering schools',
        backgroundColor: '#004777',
        skills: ["Digital Technologies", 
            "Electronic", 
            // "Industrial Data Processing", 
            "Embedded Systems", "Telecommunications", "Networks" , "Multimedia"]
    },
    {
        id: 'FOR_peip',
        date: '2013 - 2015',
        title: 'P.E.I.P',
        icon: <Icon size={1} path={mdiBookOpenPageVariant} />,
        description: 'Two-year university preparation course for the Polytech school network',
        backgroundColor: '#009CDF',
        skills: [ "Mathematics", "Physics", "Chemistry", "IT and digital", "Engineering culture" ]
    },
    {
        id: 'FOR_medecine',
        date: '2012 - 2013',
        title: 'P.A.C.E.S',
        icon: <Icon size={1} path={mdiNeedle} />,
        description: 'Intensive one-year course giving access to medical school',
        backgroundColor: '#2DA39C',
        skills: [ "Self discipline", "Organization" ]   
    },
    {
        id: 'FOR_bac',
        date: '2012',
        title: 'BACHELOR DEGREE',
        icon: <Icon size={1} path={mdiCheckDecagram} />,
        description: 'High school diploma in sciences, with honours',
        backgroundColor: '#FFD700',
        skills: []
    },
    {
        id: 'FOR_lycee',
        date: '2009 - 2012',
        title: 'High school',
        icon: <Icon size={1} path={mdiSchool} />,
        //description: 'Diploma in Science with mention',
        description: 'Three years of high school with a scientific specialization',
        backgroundColor: '#662F9D',
        skills: [ "General knowledege", "Basic science" ]
    }
];

// export const degrees = [
//     {
//         id: 'DEG_ingineer',
//         date: '2018',
//         title: 'DIPLÔME D\'INGÉNIEUR',
//         description: 'Electronique et Technologies Numériques'
//     },
//     {
//         id: 'DEG_toeic',
//         date: '2016',
//         title: 'TOEIC',
//         description: '810'
//     },
//     // {
//     //     id: 'DEG_c2i',
//     //     date: '2014',
//     //     title: 'C2I',
//     //     description: 'Certificat informatique et internet'
//     // },
//     {
//         id: 'DEG_bac',
//         date: '2013',
//         title: 'BACCALAURÉAT',
//         description: 'Mention bien'
//     }
// ];

// export const skills = [
//     {
//         skill: 'Electronics',
//         rate : 1
//     }, 
//     {
//         skill: 'Industrial Data Processing',
//         rate : 1
//     }, 
//     {
//         skill: 'Embedded Systems',
//         rate : 1
//     }, 
//     {
//         skill: 'Telecommunications',
//         rate : 1
//     }, 
//     {
//         skill: 'Networks',
//         rate : 1
//     }, 
//     {
//         skill: 'Multimedia',
//         rate : 1
//     }, 
//     {
//         skill: 'Digital Technologies',
//         rate : 1
//     }
// ];
