import {Button, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, withTheme} from '@material-ui/core'
import React, {Component} from 'react'
import {mdiCardsHeart, mdiHeartOutline} from '@mdi/js';
import {
    ContactContainer,
    ErrorSpan,
    ErrorTypography,
    HoverSpan,
    StyledCheckbox,
    StyledPaper,
    StyledTextField,
    ThankButton,
    ThankSpan
} from './styles'
import Icon from '@mdi/react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import ThumbDownAltRoundedIcon from '@material-ui/icons/ThumbDownAltRounded'
import { WaveLoading } from 'react-loadingg'

function Checkbox(props) {
    const submitSucceed  = props.submitsucceed
    const isMobile = props.ismobile === "true"
    return (
      <StyledCheckbox
        disableRipple
        color="primary"
        checkedIcon =
        { 
            <Icon 
                color={(submitSucceed === null || submitSucceed === false) ? props.palette.secondary.main : "gray" } 
                size={isMobile ? 1: 1.5} 
                path={mdiCardsHeart} /> 
        }
        icon = 
        { 
            <Icon 
                title={props.name} 
                color={(submitSucceed === null || submitSucceed === false) ? props.palette.primary.main: "gray"} 
                size={isMobile ? 1: 1.5} 
                path={mdiHeartOutline} />
        }
        {...props}
      />
    );
}

class Contact extends Component
{   
    state = {
        rating : 3,
        name: "",
        email: "",
        message: "",
        submitSucceed: null,
        errorMessage : null,
        loading: false,
    }

    onSubmit = event => {
        event.preventDefault();

        this.setState({loading: true})
        const { name, email, message, rating } = this.state

        fetch('Messages', {
            method: 'POST',
            headers: new Headers({
              'accept': '*/*',
              'content-type': 'application/json'
            }),
            body: JSON.stringify({ 
                fullName: name, 
                email: email === "" ? null: email, 
                content : message,
                rating: rating
              })
          })
          .then(response => {
              if(response.status === 200 || response.status === 201){
                this.setState({submitSucceed: true, loading: false})
              }
              else 
              {
                this.setState({submitSucceed: false, loading: false, errorMessage: `${response.status} ${response.statusText}`})
              }
          })
          .catch(error => {
                this.setState({submitSucceed: false, loading: false, errorMessage: error.toString()})
          });
    };

    handleMarkChange = (event) => {
        this.setState({rating: parseInt(event.target.name)})
    }

    handleEmailChange = (event) => {
        this.setState({email: event.target.value})
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value})
    }

    handleMessageChange = (event) => {
        this.setState({message: event.target.value})
    }

    generateCheckboxes(isMobile)
    {
        const { rating, submitSucceed } = this.state
        let labels = []
        for (let index = 1; index <= 5; index++) {
            let isChecked = index <= rating
            labels.push(<FormControlLabel key={index} value={index}
                style={isMobile ? { margin : 0} : {}}
                labelPlacement="bottom"
                control={<Checkbox ismobile={isMobile.toString()} checked={isChecked} onChange={this.handleMarkChange} name={index.toString()} palette={ this.props.theme.palette } submitsucceed={submitSucceed} />} 
                />)
        }
        return labels
    }

    render()
    {
        const { name, email, message, submitSucceed, errorMessage, loading } = this.state
        const isMobile = window.mobileCheck()
        return (
            <ContactContainer ismobile={isMobile}>
                <StyledPaper elevation={9}>
                    <form 
                            onSubmit={this.onSubmit}
                            methode="post"
                            //encType= "multipart/form-data"
                            //encType="application/json"
                            target="_self" // _parent
                        >
                        <StyledTextField 
                            id="name" 
                            label="Full name" 
                            placeholder="Full name"
                            type="text" 
                            margin="none"
                            variant="outlined"
                            required
                            size="medium"
                            fullWidth={true} 
                            autoComplete="off"
                            value={name}
                            onChange={this.handleNameChange}
                            inputProps={{ maxLength: 100 }}
                            disabled={ (submitSucceed === null || submitSucceed === false) ? false : true } />   

                        <StyledTextField 
                            id="email" 
                            label="E-mail (optional)" 
                            placeholder="E-mail"
                            type="email" 
                            margin="none"
                            variant="outlined"
                            required={false}
                            size="medium"
                            fullWidth={true} 
                            autoComplete="off"
                            value={email}
                            onChange={this.handleEmailChange}
                            inputProps={{ maxLength: 100 }}
                            disabled={ (submitSucceed === null || submitSucceed === false) ? false : true } /> 

                        <StyledTextField 
                            id="message" 
                            label= "Your message"
                            type="text" 
                            multiline={true}
                            minRows={10}
                            rows={10}
                            maxRows={10}
                            margin="none"
                            variant="outlined"
                            required={true}
                            fullWidth={true} 
                            autoComplete="off"
                            value={message}
                            onChange={this.handleMessageChange}
                            inputProps={{ maxLength: 1000 }}
                            disabled={ (submitSucceed === null || submitSucceed === false) ? false : true } />  
                        
                        <FormControl component="fieldset" style={{ marginTop: '10px', padding: '10px 10px', fullWidth: "100%"}}
                            disabled={ (submitSucceed === null || submitSucceed === false) ? false : true }>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>How was your experience on my website ?</FormLabel>
                            <FormGroup row
                                aria-label="mark" 
                                name="mark">
                                    {
                                        this.generateCheckboxes(isMobile)
                                    }
                            </FormGroup>
                        </FormControl>  

                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item> 
                                <ThankButton
                                    variant="text" 
                                    color="primary"
                                    href="/"
                                    disabled={ 
                                        (submitSucceed === null || submitSucceed === false) ? true : false 
                                    }>
                                    <ThankSpan>Thanks for your message <ThumbUpIcon fontSize="inherit" style={{ paddingLeft: '5px', verticalAlign: 'middle' }} htmlColor={this.props.theme.palette.success.main}></ThumbUpIcon> </ThankSpan>
                                    <HoverSpan>Go back to the home page<HomeRoundedIcon fontSize="inherit" style={{ paddingLeft: '5px', verticalAlign: 'middle' }} color="primary"></HomeRoundedIcon></HoverSpan>
                                </ThankButton> 

                                <ErrorTypography variant="subtitle2"
                                    color="primary"
                                    disabled={ 
                                        submitSucceed === null || submitSucceed === true? true: false 
                                    }>
                                    <ErrorSpan>Oops, an error occured. {errorMessage}<ThumbDownAltRoundedIcon fontSize="inherit" style={{ paddingLeft: '5px', verticalAlign: 'middle' }} htmlColor={this.props.theme.palette.error.main}/> </ErrorSpan>
                                </ErrorTypography> 
                            </Grid>
                           <Grid item style={{ height:'2rem'}}> 
                                {
                                    loading ?
                                    <WaveLoading color={this.props.theme.palette.primary.main} style={{ position: 'inherit'}} /> :
                                    <Button
                                        type="submit" 
                                        variant="contained" 
                                        color="primary"
                                        
                                        disabled={ (submitSucceed === null || submitSucceed === false) ? false : true }>

                                        Submit
                                    </Button> 
                                }
                            </Grid>
                        </Grid>

                    </form>

                </StyledPaper>
            </ContactContainer>
        )
    }
}

export default withTheme(Contact)