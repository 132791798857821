import React, {Component} from 'react'
import reactLogo from '../../resources/logo.svg'
import {Grid} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {
    FooterAnimatedImg,
    FooterContainer,
    FooterDivider,
    FooterGrid,
    FooterHref,
    FooterItem,
    FooterTypography
} from './styles'
import {Icon} from '@iconify/react'
import linkedinIcon from '@iconify-icons/mdi/linkedin'

class Footer extends Component
{
    render()
    {
        return (
            <FooterContainer>
                    <FooterGrid container direction="row" 
                        justifyContent="space-evenly" 
                        alignItems="center" >
                        <Grid item>
                            <FooterItem item>
                                <FooterHref variant="caption" href="https://www.linkedin.com/in/alexis-priou-310682148" target="_blank" rel="noopener noreferrer">
                                    <Icon width="30px" icon={linkedinIcon} color="white"/>
                                </FooterHref>
                            </FooterItem>
                        </Grid>
                        <Grid item>
                            <FooterDivider />
                        </Grid>
                        <Grid item>
                            <FooterItem>
                                <Link style={{ textDecoration: 'none' }}
                                        to="/contact"
                                        replace
                                        >
                                    <FooterTypography variant="caption">
                                        Contact
                                    </FooterTypography>
                                </Link>
                            </FooterItem>
                        </Grid>
                        <Grid item>
                            <FooterDivider />
                        </Grid>
                        <Grid item>
                            <FooterItem>
                                <FooterAnimatedImg src={reactLogo} alt="react">

                                </FooterAnimatedImg>
                            </FooterItem>
                        </Grid>
                </FooterGrid>
            </FooterContainer >
        )
    }
}

export default withRouter(Footer)