import {Button, Checkbox, Paper, Radio, TextField, Typography} from '@material-ui/core'
import styled from 'styled-components'
import {footerHeight} from '../../components/Footer/styles'
import { LogoSpin } from '../../keyframes/styles'

export const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '10px 0px'
}));

export const ContactContainer = styled('div')(({ theme, ismobile }) => ({
    margin:'auto',
    width: ismobile ? '100%':'80%',
    boxSizing: "border-box",
    padding:`20px 20px calc( 20px + ${footerHeight} ) 20px`,
    maxWidth :'1400px'
}));

export const StyledPaper = styled(Paper)(({theme}) => ({
    padding: '20px', 
    backgroundColor : theme.palette.primary.contrastText,
    // height: `cacl( 100% - ${footerHeight})`,
    //marginBottom: footerHeight
}));

export const StyledPaperOld = styled(Paper)(({theme}) => ({
    padding: '20px', 
    backgroundColor : theme.palette.primary.contrastText,
    // height: `cacl( 100% - ${footerHeight})`,
    //marginBottom: footerHeight
}));

export const StyledRadio = styled(Radio)(({theme}) => ({

}));

export const StyledCheckbox = styled(Checkbox)(({theme}) => ({

}));

export const ThankSpan = styled('span')(({theme}) => ({

}));

export const ErrorSpan = styled('span')(({theme}) => ({

}));

export const HoverSpan = styled('span')(({theme}) => ({
    display:'none'
}));

export const ThankButton = styled(Button)`
    display: ${props => props.disabled ? 'none' : 'inline'};
    text-transform: none;
    width: 100%;
    &:hover {
        ${ThankSpan} {
            display: none;
        }
        ${HoverSpan} {
            display: inline;
        }
    }
`

export const ErrorTypography = styled(Typography)`
    display: ${props => props.disabled ? 'none' : 'inline'};
    text-transform: none;
    width: 100%;
`

export const FooterAnimatedImg = styled.img`
   animation: ${LogoSpin} infinite 5s linear;
   `;