import { alpha, Button, Typography } from '@material-ui/core';
import styled from 'styled-components'
import { footerHeight } from '../../components/Footer/styles';

export const ProjectsContainer = styled('div')(({ theme }) => ({
    margin:'auto',
    boxSizing: "border-box",
    padding:`20px 35px calc(${footerHeight} + ${footerHeight}) 35px`,
    maxWidth :'1400px'
}));

export const StyledTypography = styled(Typography)( ({theme}) => ({
    //fontWeight: 'bold'
}));

export const StyledDescriptionTypography = styled(Typography)( ({theme}) => ({
    fontWeight: 'normal',
    fontStyle: 'italic'
}));

export const StyledTechno = styled(Typography)( ({theme}) => ({
    padding: "5px",
    //margin: "5px",
    width:'100%',
    borderRadius: "10px",
    whiteSpace:"noWrap",
    display: "inline-block",
    backgroundColor: theme.palette.primary.main
}));

export const StyledHref = styled('a')(({theme}) => ({
    '& :hover': {
      textDecoration: 'underline',
      color: theme.palette.secondary.main
    },
    textDecoration: 'underline',
    color: theme.palette.secondary.main
}));

export const Bold = styled('span')(({ theme }) => ({
    fontWeight:"bold",
    color: theme.palette.secondary.main
}));

export const ProjectButton = styled(Button)
`
    text-transform: none;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 2px ${props => alpha(props.theme.palette.primary.main,0.5)}; 
    box-shadow: 0px 2px 5px 2px ${props => alpha(props.theme.palette.primary.main,0.5)}; 
    background-color: ${props => props.theme.palette.secondary.contrastText};
    &.Mui-selected {
        background-color: ${props => props.theme.palette.primary.main};
        // box-shadow: 0px 5px 10px 2px ${props => alpha(props.theme.palette.secondary.main,0.5)}; 
        //box-shadow: 0px 2px 5px 2px ${props => alpha(props.theme.palette.secondary.main,0.5)}; 
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
        
        ${StyledTypography} {
            color: ${props => props.theme.palette.secondary.contrastText};
            font-weight: bold;
        }
        ${StyledTechno} {
            color: ${props => props.theme.palette.primary.main};
            background-color: ${props => props.theme.palette.primary.contrastText};
        }
        ${StyledDescriptionTypography} {
            color: ${props => props.theme.palette.primary.contrastText};
        }
        &:hover {
            background-color: ${props => props.theme.palette.primary.main};
            ${StyledTypography} {
                color: ${props => props.theme.palette.secondary.contrastText};
                font-weight: bold;
            }
            ${StyledTechno} {
                color: ${props => props.theme.palette.primary.main};
                background-color: ${props => props.theme.palette.primary.contrastText};
            }
            ${StyledDescriptionTypography} {
                color: ${props => props.theme.palette.primary.contrastText};
            }
        }
    }
    &:hover {
        background-color: ${props => props.theme.palette.primary.main};
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0); 
        ${StyledTypography} {
            color: ${props => props.theme.palette.secondary.contrastText};
            font-weight: bold;
        }
        ${StyledTechno} {
            color: ${props => props.theme.palette.primary.main};
            background-color: ${props => props.theme.palette.primary.contrastText};
        }
        ${StyledDescriptionTypography} {
            color: ${props => props.theme.palette.primary.contrastText};
        }
    }
`