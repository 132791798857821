import React from 'react'
import {
    CollapseContainer,
    DescriptionTypography,
    StyledTable,
    StyledTableHeadCell,
    StyledTechnoGrid,
    TechnoTypography
} from './styles'
import {Box, Grid, Paper, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import ExperienceContent from '../ExperienceContent'


function CollapseExperience ( { experienceId, collapseInfos, isMobile} )
{
    
    return(
        <Box margin='30px'>
            {(experienceId !== 'EXP_SII')?
                standardCollapseExperience(collapseInfos)
                :
                siiCollapseExperience(collapseInfos, isMobile)
            }
        </Box>
    )
}

function standardCollapseExperience(collapseInfos){
    return(
        <CollapseContainer>
            <DescriptionTypography variant="body2" 
                component="div"
                align="center"
                paragraph="false">
                {collapseInfos.description}
            </DescriptionTypography>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    //style={{ width: '100%'}}
                >
                    {
                        collapseInfos.technos.map((techno) => (
                            <StyledTechnoGrid item 
                                key={techno}>
                                    <TechnoTypography variant="caption"
                                        align="center"
                                        noWrap={true}>
                                        {techno}
                                    </TechnoTypography>
                            </StyledTechnoGrid>
                        ))  
                    }
                </Grid>
        </CollapseContainer>
    )
}

function siiCollapseExperience(collapseInfos, isMobile){
    let columnInfos = isMobile ? collapseInfos.columnInfosMobile : collapseInfos.columnInfos
    return(
        <TableContainer component={Paper}>
            <StyledTable>
                <TableHead>
                <TableRow>
                    {columnInfos.map((columnInfo, index) => (
                        index === 0 ?
                        <StyledTableHeadCell key={columnInfo} colSpan="2" align="center">{columnInfo}</StyledTableHeadCell>
                        :
                        index === columnInfos.length-1 && !isMobile?
                        <StyledTableHeadCell key={columnInfo} colSpan="2" align="center">{columnInfo}</StyledTableHeadCell>
                        :
                        <StyledTableHeadCell key={columnInfo} align="center">{columnInfo}</StyledTableHeadCell>    
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {collapseInfos.missions.map((mission) => (
                        <ExperienceContent key={mission.id} nbColumns = {isMobile ? 4: 6} experienceInfo = {mission} isMobile={isMobile}>
                            
                        </ExperienceContent>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

export default CollapseExperience