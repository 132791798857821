import {ButtonBase, Card, Typography} from "@material-ui/core";
import styled from 'styled-components';
import { footerHeight } from "../../components/Footer/styles";


export const GamesContainer = styled('div')(({ theme }) => ({
    width:'100%',
    boxSizing: "border-box",
    padding:`20px 35px calc(${footerHeight} + ${footerHeight}) 35px`,
    maxWidth :'1400px',
    margin:'auto'
}));

export const ButtonTitle = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white
}));

export const ButtonTypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  padding: '25px',
  borderRadius:'10px'
}));


export const ButtonMarked = styled('span')(({theme}) => ({
    height: "0.2rem",
    width: 18,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    bottom: -4,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity')
}));

export const BackgroundImage = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: '20%'
}));

export const ImageBackdrop = styled(Card)(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create('opacity')
}));
export const StyledButtonBase = styled(ButtonBase)`
    width: 100%;
    position: 'relative';
    height: 250px;
    &:hover {
      zIndex: 1;
      ${ButtonMarked} {
        visibility: hidden
      }
      ${ImageBackdrop} {

        box-shadow: 0em 0em 0em 0.25rem red;
        // opacity: 0.65;
        background-color: ${props => props.theme.palette.secondary.main}
      }
      ${ButtonTypography} {
        border-bottom: 0.1em solid ${props => props.theme.palette.primary.contrastText};
        //box-shadow: 0em 0em 0em 0.1em ${props => props.theme.palette.primary.contrastText};
        // background-color: ${props => props.theme.palette.primary.main}
      }
    }`;