import React from 'react';
import Layout from './pages/layout';

class App extends React.Component
{
  render()
  {
    return (
      <Layout />
    )
  }
}

export default App;
