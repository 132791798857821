import React, {Component} from 'react';
import {Link, Typography} from '@material-ui/core';
import {Container, StyledSpanFirstLetter} from './styles'


class Header extends Component
{
    render()
    {
        return (
            <Container>
                <Typography variant="h2">
                <Link href="/" variant="inherit" color="inherit" underline="none">
                    <StyledSpanFirstLetter>A</StyledSpanFirstLetter>
                    lexis Priou
                </Link>
                </Typography>
            </Container>
        )
    }
}

export default Header;