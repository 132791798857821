import styled from 'styled-components';
import {TableCell, TableRow} from '@material-ui/core';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    
}));

export const StyledCollapseTableCell = styled(TableCell)(({ theme }) => ({
    padding: 0,
    margin:0
}));

export const StyledCollapseTableRow = styled(TableRow)(({ theme }) => ({
    
}));