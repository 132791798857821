import React, {Component} from 'react'
import {columnExperiences, columnExperiencesMobile, experiences, softSkills, technicalSkills} from './data'
import ExperienceContent from './ExperienceContent'
import {ChartsContainer, Container, StyledCard, StyledPieChart, StyledTable, StyledTableCell} from './styles'
import {
    CardContent,
    CardHeader,
    Paper,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import {withTheme} from '@material-ui/core/styles'
import shuffleArray from '../../helpers/ShuffleHelper'

import 'devextreme/dist/css/dx.material.blue.light.css'

import {Font, Label, Legend, Series} from 'devextreme-react/pie-chart'


class Experience extends Component
{ 
    render()
    {
        const isMobile = window.mobileCheck()
        let columns = isMobile ? columnExperiencesMobile : columnExperiences
        return (
            <Container>
                <TableContainer component={Paper}>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                {columns.map(function(columnExperience, index) {
                                    let colSpan = isMobile?
                                        (index === 0)? 2:1 
                                        :
                                        (index === 0 || index === columns.length-1)? 2:1;
                                    return(
                                        <StyledTableCell key={columnExperience} colSpan={colSpan} align="center">
                                                <Typography variant="body2" paragraph={false}>
                                                    {columnExperience}
                                                </Typography>
                                        </StyledTableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {experiences.map((experience) => (
                                <ExperienceContent key={experience.id} nbColumns = {isMobile ? 3: 8} experienceInfo = {experience} isMobile={isMobile}>
                                    
                                </ExperienceContent>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
                <ChartsContainer>
                    <StyledCard elevation={4} ismobile={isMobile.toString()}>
                        <CardHeader  title="Soft skills" titleTypographyProps={{variant:"subtitle1"}}>
                        </CardHeader>
                        <CardContent>
                            {this.getPieChart(softSkills, 40)}
                        </CardContent>
                    </StyledCard>
                    <StyledCard elevation={4} ismobile={isMobile.toString()}>
                        <CardHeader title="Technical skills" titleTypographyProps={{variant:"subtitle1"}}>
                        </CardHeader>
                        <CardContent>
                            {this.getPieChart(technicalSkills, 30)}
                        </CardContent>
                    </StyledCard>
                </ChartsContainer>  
            </Container>
        )
    }
      
    

    getPieChart(dataSource, radialOffset) {
        var chartOptions = {
            palette: ['#273748', '#F00900', '#004777', '#2DA39C', '#FFD700', '#8BB5E1','#009CDF','#662F9D','#85DAFF','#FF615C']
        };
        return(
            <StyledPieChart
            id="softSkills"
            palette={shuffleArray(chartOptions.palette)}
            dataSource={dataSource}
            >
            <Series
                argumentField="skill"
                valueField="rate"
                hoverMode="none"
                >
                <Label
                    visible={true} 
                    customizeText={formatText}
                    position="inside"
                    radialOffset={radialOffset}
                    backgroundColor="transparent">
                    <Font 
                        size={this.props.theme.typography.caption.fontSize}
                        weight={this.props.theme.typography.caption.fontWeight}
                        color={this.props.theme.typography.caption.color}
                    />
                </Label>
            </Series>
            <Legend visible={false}/>
        </StyledPieChart>);
    }
}

function formatText(arg) {
    return `${arg.argumentText}`;
}

export default withTheme(Experience)