import {keyframes} from 'styled-components';

export const LogoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const FadeIn = keyframes`
from {
    //transform: scale(.75);
    opacity: 0;
}
to {
    //transform: scale(1);
    opacity: 1;
}
`

export const FadeInWithYTranslation = keyframes`
from {
    //transform: scale(.75);
    transform: translate(0px, 20px);
    opacity: 0;
}
to {
    //transform: scale(1);
    transform: translate(0px, 0px);
    opacity: 1;
}
`

export const FadeInWithXTranslation = keyframes`
from {
    // transform: scale(.75);
    transform: translate(20px, 0px);
    opacity: 0;
}
to {
    // transform: scale(1);
    transform: translate(0px, 0px);
    opacity: 1;
}
`

export const FadeInWithXTranslation2 = keyframes`
from {
    //transform: scale(.75);
    transform: translate(-50px, 0px);
    opacity: 0;
}
to {
    //transform: scale(1);
    transform: translate(0px, 0px);
    opacity: 1;
}
`