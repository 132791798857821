import styled from 'styled-components';
import {Card, Snackbar} from '@material-ui/core';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import { footerHeight } from '../../../components/Footer/styles';

export const HangmanContainer = styled('div')(({ theme }) => ({
    padding:"20px 35px 20px 35px",
    maxWidth :'1400px',
    margin:'auto'
}));

function getArmAdjustment(isMobile){
    return isMobile ? getBarThickness(isMobile) : 
        0
}

function getThirdBarBottomMultiplier(isMobile){
    var thirdBarBottomMultiplier = 2
    var thirdBarBottomMultiplierMobile = 1
    return isMobile ? thirdBarBottomMultiplierMobile : 
    thirdBarBottomMultiplier
}

function getBarThickness(isMobile){
    var barThickness = 1
    var barThicknessMobile = 1
    return isMobile ? barThicknessMobile : 
        barThickness
}

function getHangmanTreeHeightContainer(isMobile){
    var hangmanTreeHeightContainer = 20
    var hangmanTreeHeightContainerMobile = 16
    return isMobile ? hangmanTreeHeightContainerMobile : 
        hangmanTreeHeightContainer
}

function getVerticalPosition(isMobile){
    var verticalPosition = 25
    var verticalPositionMobile = 15
    return isMobile ? verticalPositionMobile : 
        verticalPosition
}

function getFirstBarWidth(isMobile){
    var firstBarWidth = 10
    var firstBarWidthMobile = 8
    return isMobile ? firstBarWidthMobile : 
        firstBarWidth
}

function getSecondBarHeigth(isMobile){
    var secondBarHeigth = 15
    var secondBarHeigthMobile = 13
    return isMobile ? secondBarHeigthMobile : 
        secondBarHeigth
}

function getThridBarWidth(isMobile){
    //var thridBarWidth = 14;
    //var thridBarWidthMobile = 9;
    var thridBarWidth = 14;
    var thridBarWidthMobile = (35 * window.innerWidth /100)/16;
    return isMobile ? thridBarWidthMobile : 
        thridBarWidth
}

function getFourthBarHeight(isMobile){
    var fourthBarHeight = 2
    var fourthBarHeightMobile = 2
    return isMobile ? fourthBarHeightMobile : 
        fourthBarHeight
}

function getBodyHeight(isMobile){
    var bodyHeight = 6
    var bodyHeightMobile = 4
    return isMobile ? bodyHeightMobile : 
        bodyHeight
}

function getBodyThickness(ismobile){
    return getBarThickness(ismobile) /2
}

export const HangmanTreeContainer = styled('div')(({ theme, ismobile }) => ({
    outline: `0.08em solid ${theme.palette.primary.main}`,
    height: `${getHangmanTreeHeightContainer(ismobile)}rem`,
    backgroundColor: theme.palette.primary.contrastText,
    maxWidth :'700px',
    margin: 'auto'
}));

export const FirstBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getFirstBarWidth(ismobile)}rem`,
    height: `${getBarThickness(ismobile)}rem`,
    top: `${getHangmanTreeHeightContainer(ismobile) - getBarThickness(ismobile)}rem`,
    left: `${getVerticalPosition(ismobile)}%`
}));

export const SecondBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBarThickness(ismobile)}rem`,
    height: `${getSecondBarHeigth(ismobile)}rem`,
    top: `${getHangmanTreeHeightContainer(ismobile) - 2*getBarThickness(ismobile) - getSecondBarHeigth(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2}rem - ${getBarThickness(ismobile)/2}rem)`
}));

export const ThirdBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getThridBarWidth(ismobile)}rem`,
    height: `${getBarThickness(ismobile)}rem`,
    bottom: `${getSecondBarHeigth(ismobile)-getThirdBarBottomMultiplier(ismobile)*getBarThickness(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile)/2}rem)`
}));

export const FourthBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBarThickness(ismobile)}rem`,
    height: `${getFourthBarHeight(ismobile)}rem`,
    bottom:`${getSecondBarHeigth(ismobile)-getThirdBarBottomMultiplier(ismobile)*getBarThickness(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile)/2 + 3*getThridBarWidth(ismobile)/4}rem)`
}));

export const FifthBar = styled('div')(({ theme, ismobile }) => ({
    boxShadow: `0em 0em 0em 0.5em ${theme.palette.primary.main}`,
    borderRadius: `${getBarThickness(ismobile)}rem`,
    position:"relative",
    width:`${getBarThickness(ismobile)*2}rem`,
    height: `${getBarThickness(ismobile)*2}rem`,
    bottom:`${getSecondBarHeigth(ismobile)-getThirdBarBottomMultiplier(ismobile)*getBarThickness(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile) + 3*getThridBarWidth(ismobile)/4}rem)`
}));

export const SixthBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBodyThickness(ismobile)}rem`,
    height: `${getBodyHeight(ismobile)}rem`,
    bottom:`${getSecondBarHeigth(ismobile)-getThirdBarBottomMultiplier(ismobile)*getBarThickness(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBodyThickness(ismobile)/2 + 3*getThridBarWidth(ismobile)/4}rem)`
}));

var footHeight = 3
export const SeventhBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBodyThickness(ismobile)}rem`,
    height: `${footHeight}rem`,
    bottom:`${getSecondBarHeigth(ismobile)-getThirdBarBottomMultiplier(ismobile)*getBarThickness(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile)/4 + 3*getThridBarWidth(ismobile)/4}rem)`,
    transform: `rotate(30deg)`,
    transformOrigin: 'top right'
}));

export const EighthBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBodyThickness(ismobile)}rem`,
    height: `${footHeight}rem`,
    bottom:`${getSecondBarHeigth(ismobile)-getThirdBarBottomMultiplier(ismobile)*getBarThickness(ismobile)+footHeight+getBodyThickness(ismobile)/2}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile)/4 + 3*getThridBarWidth(ismobile)/4}rem)`,
    transform: `rotate(-30deg)`,
    transformOrigin: 'top right'
}));

export const NinthBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBodyThickness(ismobile)}rem`,
    height: `${footHeight}rem`,
    bottom:`${getSecondBarHeigth(ismobile)+footHeight+getBodyHeight(ismobile)+getArmAdjustment(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile)/4 + 3*getThridBarWidth(ismobile)/4}rem)`,
    transform: `rotate(-30deg)`,
    transformOrigin: 'top left'
}));

export const TenthBar = styled('div')(({ theme, ismobile }) => ({
    backgroundColor: theme.palette.primary.main,
    position:"relative",
    width:`${getBodyThickness(ismobile)}rem`,
    height: `${footHeight}rem`,
    bottom:`${getSecondBarHeigth(ismobile)+footHeight+getBodyHeight(ismobile)+footHeight+getArmAdjustment(ismobile)}rem`,
    left: `calc(${getVerticalPosition(ismobile)}% + ${getFirstBarWidth(ismobile)/2 - getBarThickness(ismobile)/4 + 3*getThridBarWidth(ismobile)/4}rem)`,
    transform: `rotate(30deg)`,
    transformOrigin: 'top right'
}));

// export const CurrentWordContainer = styled(Grid)(({ theme, isMobile }) => ({
//     //display: "flex",
//     //margin: "0 0 20px 0",
//     flexWrap: "noWrap",
//     userSelect: "none",
//     paddingBottom: '1rem'
// }));

export const CurrentWordContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexWrap: "noWrap",
    //margin: "0 0 20px 0",
    userSelect: "none"
}));

export const AlphabetContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    paddingTop: '1rem',
    paddingBottom : footerHeight,
    //margin: "auto",
    userSelect: "none",
}));

export const Letter = styled(Card)(({ theme, letterstate, ismobile }) => ({
    //flex: 1,
    //margin:"10px",
    width:'100%',
    //padding: '0 0.5rem',
    background: letterstate === 'hidden' ? 
    `${theme.palette.primary.main}` 
    : 
    letterstate === 'loose-revealed' ? 
    `${alpha(theme.palette.error.main, 1)}`
    :
    `${alpha(theme.palette.success.main, 1)}`,
    boxShadow: 
        letterstate === 'hidden' ? 
            "none"  
        : 
        `0em 0em 0em ${ismobile ? '0.1rem' : '0.2rem'} ${theme.palette.primary.main}`
}));

export const AlphabetLetter = styled(Card)(({ theme, letterstate, ismobile }) => ({
    flex: "1 1 calc(100% / 7 - 10px)",
    margin:"5px",
    cursor: "pointer",
    background: letterstate === 'unClicked' ? 
        `${theme.palette.primary.main}` : 
        letterstate === 'unMatched' ? `${theme.palette.error.main}` : `${theme.palette.success.main}`,
    boxShadow: 
        letterstate === 'unClicked' ? 
            "none"  
        : `0em 0em 0em ${ismobile ? '0.1rem' : '0.2rem'} ${theme.palette.primary.main}`
}));

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    marginBottom:"4rem",
    cursor: "pointer"
}));