import {CardContent, Grid, withTheme, Zoom as MaterialZoom} from '@material-ui/core'
import Zoom from 'react-medium-image-zoom'
import React, {Component, useState} from 'react'
import Carousel from 'react-material-ui-carousel'
import 'react-medium-image-zoom/dist/styles.css'
import { ProjectContainer, ScreensContainer, StyledCard, StyledGrid} from './styles'
import { withRouter } from 'react-router'
import projectList from '../../projects/projectList'
import { CircleToBlockLoading } from 'react-loadingg'

class Application extends Component
{
    initialDelay = 200
    maxCarouselGroupedSize = 3
    maxImgHeightPercentage = 70
    imgHeight = 2680
    imgWidth = 1280

    state = {
        height: 0,
        width: 0
    }

    handleResize = () => {
        const { project } = this.state
        this.setState({ screensGrouped : this.getScreensGrouped(project.screens)})
    }; 

    getScreensGrouped(screens) {

        let screensGrouped = []
        const imgHeight = this.imgHeight
        const imgWidth = this.imgWidth
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight
        const containerWidth = 80 * windowWidth /100 // take 80% of width 
        const containerHeight = 65 * windowHeight /100 // take 50% of height 

        let bestImgHeightpercentage = 0
        let bestCarouselGroupedSize = this.maxCarouselGroupedSize
        for (let carouselGroupedSize = this.maxCarouselGroupedSize; carouselGroupedSize > 0; carouselGroupedSize--) {
            let newImgWidth = containerWidth / (carouselGroupedSize+1)

            let newImgHeight = imgHeight * newImgWidth / imgWidth
            let imgHeightPercentage = newImgHeight *100 /containerHeight
            if( imgHeightPercentage < 100 
                && imgHeightPercentage > bestImgHeightpercentage){
                bestImgHeightpercentage = imgHeightPercentage
                bestCarouselGroupedSize = carouselGroupedSize
            }
        }

        let carouselIndex = 0
        let screenIndex = 0
        for (let index = 0; index < screens.length; index++) {
            if(index % bestCarouselGroupedSize === 0){
                if(index !== 0){
                    carouselIndex++
                    screenIndex=0
                }
            }

            if(!screensGrouped[carouselIndex]){
                screensGrouped[carouselIndex] = []
            }

            screensGrouped[carouselIndex][screenIndex] = screens[index]
            screenIndex++
        }

        return screensGrouped;
    }

    componentWillMount(){
        const { pathname }= this.props.location
        const projectName = pathname.split('/').pop()
        const project = projectList.find(project => project.link.toLowerCase() === projectName)
        this.setState({ project : project, screensGrouped : this.getScreensGrouped(project.screens) })
    
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleResize);
    }

    render()
    {
        const { screensGrouped, project } = this.state

        return (
            <ProjectContainer> 
                <div style={{ width:'100%', maxWidth :'1400px', margin:'auto'}}>
                    <MaterialZoom in={true} direction="down" timeout={1000}>
                        <StyledCard>
                            <CardContent style={{ padding: "15px" }}>
                                {project.styledDescription}
                            </CardContent>
                        </StyledCard>
                    </MaterialZoom>
                    <ScreensContainer elevation="4">
                            <Carousel
                                indicators={true}
                                autoPlay={true}
                                interval="5000"
                                stopAutoPlayOnHover={true}
                                animation="slide"
                                swipe={true}
                                navButtonsAlwaysVisible={false}
                                cycleNavigation={true}
                                timeout={500}
                                //navButtonsAlwaysVisible={true}
                            > 
                                {
                                    screensGrouped.map( (screens, i) => 
                                        <CarouselItem key={i} screens={screens} theme={this.props.theme}/> 
                                    )
                                }
                            </Carousel>
                    </ScreensContainer>
                </div>
            </ProjectContainer>
        )
    }
}

function CarouselItem(props)
{
    const screens = props.screens
    let xs=GetXsValue()
    const [isLoad, setIsLoad] = useState(false);
    
    return (
        <StyledGrid container
        direction="row"
        justifyContent="space-around" 
        alignItems="flex-start"
        wrap="nowrap"
        >
            {
                props.screens.map((screen, index) =>
                {
                    return(
                        <Grid key={index} item xs={xs}
                        style={{ textAlign: 'center'}}
                        >
                                {
                                !isLoad && 
                                    <div style={{ 
                                        //height:'30vh', 
                                        //width:'100%'
                                    }}
                                    >
                                        <CircleToBlockLoading color={props.theme.palette.primary.main} 
                                            style={{ 
                                                //padding: '10vh',
                                                margin:'auto'
                                            }} 
                                            />
                                    </div>
                                }
                                
                                <Zoom> 
                                    <img src={screen} 
                                        onLoad={setIsLoad}
                                        style={{
                                            width:'100%'
                                        }}
                                        alt={screen}>
                                    </img>
                                </Zoom>
                            
                            {/* <WaveLoading color="primary" style={{ position: 'inherit'}} /> */}
                                
                        </Grid>
                )}
                )
            }
            </StyledGrid>
    )

    function GetXsValue() {
        switch (screens.length) {
            case 3:
                return 2
            case 2:
                return 3
            case 1:
                return 6
            default:
                return
        }
    }
}

export default withRouter(withTheme(Application))