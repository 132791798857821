import {Typography, withTheme} from '@material-ui/core'
import React, {Component} from 'react'
import {UnfoundContainer} from './styles'
import {mdiEmoticonSad} from '@mdi/js'
import Icon from '@mdi/react'

class Unfound extends Component
{   
    render()
    {
        return (
            <UnfoundContainer>
                <Typography variant="h1" align="center"
                    style={{ paddingTop: '10rem'}}>
                    Oops, I can't find that page
                </Typography>
                <div style={{ textAlign: "center", paddingTop: '2rem' }}>
                    <Icon 
                        color={ this.props.theme.palette.secondary.main } 
                        size={3} 
                        path={mdiEmoticonSad} /> 
                </div>
            </UnfoundContainer>
        )
    }
}

export default withTheme(Unfound)