import React, {Component} from 'react'
import WORDS from './words'
import {
    AlphabetContainer,
    AlphabetLetter,
    CurrentWordContainer,
    EighthBar,
    FifthBar,
    FirstBar,
    FourthBar,
    HangmanContainer,
    HangmanTreeContainer,
    Letter,
    NinthBar,
    SecondBar,
    SeventhBar,
    SixthBar,
    StyledSnackbar,
    TenthBar,
    ThirdBar
} from './styles'
import {Grid, Typography} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import HelpIcon from '@material-ui/icons/Help'

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
const LAST_HANGMAN_PART = 10
class Hangman extends Component
{
    state = {
        currentWord: WORDS[Math.floor(Math.random() * (WORDS.length))].split(''),
        doneWords : [],
        clickedLetterIndexes: [],
        displayWonMessage: false,
        displayLooseMessage: false
    }

    restart = () =>
    {
        const { currentWord, doneWords } = this.state
        let newDoneWords = [...doneWords, currentWord.join('')]
        let remainingWords = WORDS.filter(w => !newDoneWords.includes(w))

        if(remainingWords.length === 0){
            remainingWords = WORDS
            newDoneWords = []
        }

        this.setState(
            {
                currentWord: remainingWords[Math.floor(Math.random() * (remainingWords.length))].split(''),
                doneWords : newDoneWords,
                clickedLetterIndexes: [],
                displayWonMessage: false,
                displayLooseMessage: false
            }
        )
    }

    handleAlphabetClick = index =>
    {
        const { currentWord, clickedLetterIndexes, displayWonMessage, displayLooseMessage } = this.state

        if(displayLooseMessage || displayWonMessage){
            return
        }

        if(clickedLetterIndexes.includes(index)){
            return
        }

        var failedLettersCount = this.getFailedLettersCount([...clickedLetterIndexes, index], currentWord);
        if(failedLettersCount === LAST_HANGMAN_PART){
            this.setState({ displayLooseMessage: true })
        }

        if(clickedLetterIndexes.length+1 - failedLettersCount === this.getDistinctLetterCount(currentWord))
        {
            this.setState({ displayWonMessage: true })
        }

        this.setState({ clickedLetterIndexes: [...clickedLetterIndexes, index] })
    }

    getDistinctLetterCount(currentWord) {
        var lettersProcessed = []
        for (let index = 0; index < currentWord.length; index++) {
            if(!lettersProcessed.includes(currentWord[index])){
                lettersProcessed.push(currentWord[index])
            }
        }

        return lettersProcessed.length
    }

    getAlphabetLetterState(index){
        const { clickedLetterIndexes, currentWord } = this.state
        
        if(clickedLetterIndexes.includes(index))
        {
            if(currentWord.includes(ALPHABET[index])){
                return 'matched'
            }else{
                return 'unMatched'
            }
        }
        else
        {
            return 'unClicked'
        }
    }

    getCurrentWordLetterState(index)
    {
        const { clickedLetterIndexes, currentWord, displayLooseMessage } = this.state

        var letterIndex = ALPHABET.indexOf(currentWord[index])
        
        if(clickedLetterIndexes.includes(letterIndex))
        {
            return 'revealed'
        }
        else if(displayLooseMessage)
        {
            return 'loose-revealed'
        } else
        {
            return 'hidden'
        }
    }

    isHangmanPartHidden(index)
    {
        const { clickedLetterIndexes, currentWord } = this.state

        var failedLettersCount = this.getFailedLettersCount(clickedLetterIndexes, currentWord);

        return index > failedLettersCount
    }

    getFailedLettersCount(clickedLetterIndexes, currentWord) {
        var failedLettersCount = clickedLetterIndexes.length;

        var lettersProcessed = []
        for (let index = 0; index < currentWord.length; index++) {
            const letter = currentWord[index];
            if (!lettersProcessed.includes(letter) && clickedLetterIndexes.includes(ALPHABET.indexOf(letter))) {
                failedLettersCount--;
                lettersProcessed.push(letter)
            }
        }
        return failedLettersCount;
    }

    render()
    {
        const { currentWord, displayWonMessage, displayLooseMessage } = this.state
        const isMobile = window.mobileCheck()
        const itemXs = Math.round(12/currentWord.length)
        if(itemXs < 1){
            itemXs = 1
        }
        if(itemXs > 12){
            itemXs = 12
        }
        return (
          <HangmanContainer>
            <CurrentWordContainer 
            >
              {currentWord.map((letter, index) => 
                {
                    const currentWordLetterState = this.getCurrentWordLetterState(index)
                    return(
                        <div style={{flex:1, padding:'1rem 0.2rem'}}>
                            <Letter
                            key={index}
                            ismobile={isMobile}
                            letterstate={currentWordLetterState}
                            >
                                <Typography variant="body2" align="center" style={{lineHeight: 2.5}}>
                                    {currentWordLetterState === 'hidden' ? <HelpIcon htmlColor="white" style={{ width: '100%', verticalAlign:'middle', fontSize: '1.3rem'}} /> : letter }
                                </Typography>
                            </Letter>
                        </div>
                    )
                }
              
              )}
            </CurrentWordContainer>

            <HangmanTreeContainer ismobile={isMobile}>
                <FirstBar ismobile={isMobile} hidden={this.isHangmanPartHidden(1)}/>
                <SecondBar ismobile={isMobile} hidden={this.isHangmanPartHidden(2)}/>
                <ThirdBar ismobile={isMobile} hidden={this.isHangmanPartHidden(3)}/>
                <FourthBar ismobile={isMobile} hidden={this.isHangmanPartHidden(4)}/>
                <FifthBar ismobile={isMobile} hidden={this.isHangmanPartHidden(5)}/>
                <SixthBar ismobile={isMobile} hidden={this.isHangmanPartHidden(6)}/>
                <SeventhBar ismobile={isMobile} hidden={this.isHangmanPartHidden(7)}/>
                <EighthBar ismobile={isMobile} hidden={this.isHangmanPartHidden(8)}/>
                <NinthBar ismobile={isMobile} hidden={this.isHangmanPartHidden(9)}/>
                <TenthBar ismobile={isMobile} hidden={this.isHangmanPartHidden(LAST_HANGMAN_PART)}/>
            </HangmanTreeContainer>

            <AlphabetContainer>
              {
                ALPHABET.map((letter, index) => (
                    <AlphabetLetter
                        key={index}
                        ismobile={isMobile}
                        onClick={() => this.handleAlphabetClick(index)}
                        letterstate={this.getAlphabetLetterState(index)}
                    >
                        <Typography 
                            variant="body2" 
                            align="center"
                            style={{lineHeight: 2}} 
                            >
                                {letter}
                        </Typography>
                    </AlphabetLetter>
                ))
                }

            </AlphabetContainer>
            
            <StyledSnackbar 
                open={displayWonMessage} 
                >
                <Alert severity="success" onClick={() => this.restart()}>
                    Bravo ! Click here to restart !
                </Alert>
            </StyledSnackbar>
            <StyledSnackbar 
                open={displayLooseMessage} 
                >
                <Alert severity="error" onClick={() => this.restart()}>
                    Bad luck, you loose ! Click here to try again !
                </Alert>
            </StyledSnackbar>
            
          </HangmanContainer>
        );
    }
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Hangman