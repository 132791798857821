import {Divider, Grid, Typography} from '@material-ui/core'
import styled from 'styled-components'
import {LogoSpin} from '../../keyframes/styles'

export const footerHeight = '4rem';

export const FooterContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  padding: 10,
  boxSizing: "border-box",
  position: "absolute",
  height: footerHeight,
  bottom: 0,
  left: 0,
  right: 0
}));

export const FooterItem = styled(Grid)(({ theme }) => ({

}));

export const FooterGrid = styled(Grid)(({ theme }) => ({
  height: "100%",
  margin: "auto"
}));

export const FooterDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  margin: '0 50%',
  width: 2,
  height: 40,
}));

export const FooterHref = styled('a')({
  '& :hover': {
    textDecoration: 'underline',
  },
  textDecoration: 'none',
});

export const FooterTypography = styled(Typography)(({ theme }) => ({

}));

export const FooterImg = styled.img({
  height: '2rem'
});

export const FooterAnimatedImg = styled.img`
   animation: ${LogoSpin} infinite 20s linear;
   height: 30px
   `;