import {Route} from 'react-router-dom';
import React from 'react';

import Application from '../pages/projects/Application';
import Polyjoule from '../pages/projects/Polyjoule';

export default [
    <Route key="polyjoule-android" exact path="/projects/polyjoule-android" component={Application} />,
    <Route key="polyjoule-unity" exact path="/projects/polyjoule-unity" component={Application} />,
    <Route key="polyjoule-policies" exact path="/projects/polyjoule/policies" component={Polyjoule} />
];
