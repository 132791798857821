const gameList = [
    {
        title: 'Memory',
        link: '/memory'
    },
    {
        imageSrc: '',
        title: 'Hangman',
        link: '/hangman'
    }
];

export default gameList