import {Link} from 'react-router-dom'
import React, {Component} from 'react'
import projectList from './projectList'
import { ProjectsContainer, StyledDescriptionTypography, ProjectButton, StyledTechno, StyledTypography } from './styles'
import { Grid } from '@material-ui/core'

class Projects extends Component
{
    state = {
        isSmallScreen : this.isSmallScreen(),
        // isMobile : window.mobileCheck()
    }

    handleResize = () => {
        this.setState({ isSmallScreen : this.isSmallScreen()})
    }; 

    isSmallScreen() {
        return window.innerWidth < 770
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleResize);
    }

    render()
    {
        const {isSmallScreen} = this.state
        const isMobile = window.mobileCheck()
        return (
            <ProjectsContainer>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                    spacing={5}
                    >
                    {
                        projectList.map( ( { title, link, technos, description} ) => 
                        {
                            return (
                                <Grid 
                                key={title}
                                item 
                                xs={ (isMobile || isSmallScreen) ? 12 : 6} //style={{ width: '50%', margin: 'auto'}}
                                >
                                    <Link 
                                        style={{ textDecoration: 'none'}} 
                                        to={{
                                            pathname: link !== '/' ?`${this.props.location.pathname}/${link}` : '/',
                                        }}
                                        >
                                            <ProjectButton>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="stretch"
                                                    spacing={2}>
                                                        <Grid item>
                                                            <StyledTypography 
                                                                    variant="body1" 
                                                                    align="center" 
                                                                    //noWrap={true}
                                                                    >
                                                                {title} 
                                                            </StyledTypography>
                                                        </Grid>
                                                        <Grid item>
                                                            <StyledDescriptionTypography 
                                                                    variant="subtitle2" 
                                                                    align="center" 
                                                                    //noWrap={true}
                                                                    >
                                                                {description} 
                                                            </StyledDescriptionTypography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="space-evenly"
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                                >
                                                                {technos.map((techno,index)=> (
                                                                    <Grid item xs={isMobile ? 6:4} key={techno}> 
                                                                        <StyledTechno 
                                                                            variant="h3" 
                                                                            align="center"
                                                                            noWrap={false}>
                                                                            {techno}
                                                                        </StyledTechno>
                                                                    </Grid>   
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                </Grid>
                                            </ProjectButton>
                                    </Link>
                                </Grid>
                            )
                        }) 
                    }
                </Grid>
            </ProjectsContainer>
        )
    }
}

export default Projects