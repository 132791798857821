import styled from 'styled-components'
import {Card, Table, TableCell} from '@material-ui/core'
import PieChart from 'devextreme-react/pie-chart'
import {footerHeight} from '../../components/Footer/styles';


export const Container = styled('div')(({ theme }) => ({
    padding:"20px 35px 20px 35px",
    maxWidth :'1400px',
    margin:'auto'
}));

export const ChartsContainer = styled('div')(({ theme }) => ({
    padding: `40px 0px calc(40px + ${footerHeight}) 0px`,
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    flexWrap:"wrap"
}));

export const StyledTable = styled(Table)(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
}));

export const StyledPieChart = styled(PieChart) (( {theme}) => ({
    
}))

export const StyledCard = styled(Card) (( {theme, ismobile }) => ({
    width: ismobile === "true" ? "100%": "48%",
    marginBottom: ismobile? '20px' : '0',
    backgroundColor: theme.palette.primary.contrastText
}))