import React from 'react'
import {StyledCollapseTableCell, StyledCollapseTableRow, StyledTableCell, StyledTableRow} from './styles'
import cdiscountLogo from '../../../resources/cdiscount.svg'
import desoutterLogo from '../../../resources/desoutter.svg'
import greenwichLogo from '../../../resources/greenwich.svg'
import siiLogo from '../../../resources/sii.svg'
import ceaLogo from '../../../resources/cea.svg'

import {Collapse, IconButton, Typography} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CollapseExperience from '../CollapseExperience'


function ExperienceContent ( { nbColumns, experienceInfo, isMobile} ){
    
    const [open, setOpen] = React.useState(false);
    return(
        <>
            <StyledTableRow 
            hover
            onClick={() => setOpen(!open)}
            >
                <StyledTableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <img src={extractCompanyLogoPath(experienceInfo.companyLogo)} 
                    width="90px"
                    style={{verticalAlign:'middle'}}
                    alt={experienceInfo.company}></img>
                </StyledTableCell>
                {(experienceInfo.position) ? 
                    <>
                        <StyledTableCell align="center" valign="middle">
                            <Typography variant="body1">
                                {experienceInfo.position}
                            </Typography> 
                        </StyledTableCell>
                        {!isMobile &&
                        <StyledTableCell align="center" valign="middle">
                            <Typography variant="body1">
                                {experienceInfo.contract}
                            </Typography> 
                        </StyledTableCell>}
                    </> : <></>
                }
                { !isMobile &&
                    <>
                        <StyledTableCell align="center" valign="middle">
                            <Typography variant="body1">
                                {experienceInfo.city}
                            </Typography> 
                        </StyledTableCell>
                        <StyledTableCell align="center" valign="middle">
                            <Typography variant="body1">
                                {experienceInfo.country}
                            </Typography> 
                        </StyledTableCell>
                        <StyledTableCell align="center" valign="middle">
                            <Typography variant="body1">
                                {extractDate(experienceInfo.startDate)}
                            </Typography> 
                        </StyledTableCell>
                        <StyledTableCell align="center" valign="middle">
                            <Typography variant="body1">
                                {extractDate(experienceInfo.endDate)}
                            </Typography> 
                        </StyledTableCell>
                    </> 
                } 
            </StyledTableRow>

            <StyledCollapseTableRow>
                <StyledCollapseTableCell colSpan={nbColumns}>
                    <Collapse in={open} timeout="auto">
                        <CollapseExperience experienceId = {experienceInfo.id} collapseInfos = {experienceInfo.collapseInfos} isMobile={isMobile}>
                        </CollapseExperience>
                    </Collapse>
                </StyledCollapseTableCell>
            </StyledCollapseTableRow>
        </>
    )
}

function extractDate(date){
    const formatDate = new Intl.DateTimeFormat('en-US').format
    if(date != null){
        return formatDate(new Date(date))
    }

    return '-'
}

export function extractCompanyLogoPath(companyLogo){
    switch(companyLogo){
        case "desoutter":
            return desoutterLogo;
        case "cdiscount":
            return cdiscountLogo;
        case "greenwich":
            return greenwichLogo;
        case "sii":
            return siiLogo;
        case "cea":
            return ceaLogo;
        default:
            return cdiscountLogo;
    }
}

export default ExperienceContent