import React, {Component} from 'react'
import {Container, GridItem, ScrolledGrid, StyledTab, StyledTabs, TabContent} from './styles'
import routes from '../../routes/routes'
import {Typography} from '@material-ui/core'
import tabs from '../../pages/mainPage/tabs'
import {Link, Switch, withRouter} from 'react-router-dom'
import PropTypes from "prop-types"
import {ParallaxProvider} from 'react-scroll-parallax'
import ScrollToTop from '../../helpers/ScrollToTop'

class MainPage extends Component
{
    constructor(props) {
        super(props)
        this.state = {
          selectedTab: tabs.findIndex((t) => props.location.pathname.includes(t.link)) === -1 ? 
            false : 
            tabs.findIndex((t) => props.location.pathname.includes(t.link))
        };
      }

    renderTab = () =>
    {
        let isMobile = window.mobileCheck()
        return (
            tabs.map((tab) =>
            {
                const { link, id, label, icon, disabled } = tab
                return (
                    <StyledTab
                        component={Link}
                        disabled={disabled}
                        icon={icon}
                        id={id}
                        key={id}
                        label={!isMobile ?  <Typography variant="h3">{label}</Typography>: ''}
                        to={link}
                    />
                )
            })
        )
    }

    handleTabChange = (event, newSelectedTab) => {
        this.setState({ selectedTab : newSelectedTab} )
    };

    render()
    { 
        return (
            <Container>
                <ScrolledGrid 
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                >
                    <GridItem item>
                        <StyledTabs value={this.state.selectedTab} 
                        onChange={this.handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                visibility:"hidden"
                            }
                          }}
                        >
                            {this.renderTab()}
                        </StyledTabs>
                    </GridItem>
                </ScrolledGrid>
                <ParallaxProvider>
                    <TabContent>
                        <ScrollToTop />
                        <Switch>
                            {routes.map((route) => { return route })}
                        </Switch>
                    </TabContent>
                </ParallaxProvider>
            </Container>
        );
    }
}

MainPage.propTypes = {
    location: PropTypes.object.isRequired
};

const MainPageWithRouter = withRouter(MainPage);

export default MainPageWithRouter;