import React from 'react'
import { Typography } from '@material-ui/core';
import { StyledHref, Bold } from './styles';
import polyjoule_achievements_2017 from '../../resources/polyjoule_achievements_2017.png'
import polyjoule_game from '../../resources/polyjoule_game.png'
import polyjoule_game11 from '../../resources/polyjoule_game11.png'
import polyjoule_homepage from '../../resources/polyjoule_homepage.png'
import polyjoule_login from '../../resources/polyjoule_login.png'
import polyjoule_social_media_twitter from '../../resources/polyjoule_social_media_twitter.png'
import wifistate_app_logo from '../../resources/wifistate_app_logo.png'
import wifistate_bssid from '../../resources/wifistate_bssid.png'
import wifistate_channel from '../../resources/wifistate_channel.png'
import wifistate_create_campaign from '../../resources/wifistate_create_campaign.png'
import wifistate_gettingstart from '../../resources/wifistate_gettingstart.png'
import wifistate_homepage2 from '../../resources/wifistate_homepage2.png'
import wifistate_load_screen from '../../resources/wifistate_load_screen.png'
import wifistate_map_configuration from '../../resources/wifistate_map_configuration.png'
import wifistate_map_configuration2 from '../../resources/wifistate_map_configuration2.png'
import wifistate_scanner from '../../resources/wifistate_scanner.png'
import wifistate_scanner3 from '../../resources/wifistate_scanner3.png'
import wifistate_strength from '../../resources/wifistate_strength.png'
import polyjoule_unity_game from '../../resources/polyjoule_unity_game.png'
import polyjoule_unity_homepage from '../../resources/polyjoule_unity_homepage.png'
import polyjoule_unity_leaderboard from '../../resources/polyjoule_unity_leaderboard.png'

const projectList = [
    {
        title: 'WifiState',
        year: '2019',
        link: 'wifistate',
        description: 'WifiState is a cross-platform application which allow you to measure and map the wifi signal strength.',
        technos: ['Xamarin', 'C#', 'Visual studio', 'cross-platform', 'Android', 'iOS'],
        screens: [
            wifistate_app_logo,
            wifistate_load_screen,
            wifistate_gettingstart,
            wifistate_create_campaign,
            wifistate_scanner,
            wifistate_scanner3,
            wifistate_homepage2,
            wifistate_map_configuration,
            wifistate_map_configuration2,
            wifistate_strength,
            wifistate_channel,
            wifistate_bssid
        ], 
        styledDescription: <Typography variant="body1" align="center">
                WifiState is a <Bold>multi-platform</Bold> application built using <Bold>Xamarin</Bold> which allow you to measure and map the wifi signal strength.
        </Typography>,        
        styledDescriptionMobile: <Typography variant="body1" align="center" style={{fontSize:'1rem'}}>
                WifiState is a <Bold>multi-platform</Bold> application built using <Bold>Xamarin</Bold> which allow you to measure and map the wifi signal strength.
        </Typography>
    },
    {
        title: 'Polyjoule - Android',
        year: '2018',
        link: 'polyjoule-android',
        description: 'Polyjoule is an android application which is composed of a game, and various communication views about the association.',
        technos: ['Android', 'Java', 'Android studio', 'Gradle', 'Twitter4J', 'Google play'],
        screens: [
            polyjoule_homepage,
            polyjoule_game,
            polyjoule_game11,
            polyjoule_achievements_2017,
            polyjoule_login,
            polyjoule_social_media_twitter
        ],       
        styledDescription:  <Typography variant="body1" align="center">
            <StyledHref variant="caption" href="https://polytech.univ-nantes.fr/fr/une-ecole-sur-3-campus/actualites/lassociation-polyjoule-lance-son-application-mobile" target="_blank" rel="noopener noreferrer">
                <Bold>Polyjoule</Bold>
            </StyledHref> is an android application built using <Bold>Android Studio</Bold> which is composed of a game, and various communication views about the association.
        </Typography>,       
        styledDescriptionMobile:  <Typography variant="body1" align="center" style={{fontSize:'1rem'}} >
            <StyledHref variant="caption" href="https://polytech.univ-nantes.fr/fr/une-ecole-sur-3-campus/actualites/lassociation-polyjoule-lance-son-application-mobile" target="_blank" rel="noopener noreferrer">
                <Bold>Polyjoule</Bold>
            </StyledHref> is an android application built using <Bold>Android Studio</Bold> which is composed of a game, and various communication views about the association.
        </Typography>
    },
    {
        title: 'Portfolio',
        year: '2021',
        link: '/',
        description: 'This portfolio is half a resume about me and half a front-end environment made for experimentation.',
        technos: ['React', 'Javascript', 'Visual code', 'Firestore', 'Cloud Run', 'css' ]
    },
    {
        title: 'Polyjoule - Unity',
        year: '2022',
        link: 'polyjoule-unity',
        description: 'Polyjoule is a multi-platform application which is composed of a game, players are able to share their highscores.',
        technos: ['Unity', 'C#', 'Visual studio', 'Google Cloud', 'Firestore', 'ASP.NET Core'],
        screens: [
            polyjoule_unity_homepage,
            polyjoule_unity_game,
            polyjoule_unity_leaderboard
        ],       
        styledDescription:  <Typography variant="body1" align="center">
            <StyledHref variant="caption" href="https://polytech.univ-nantes.fr/fr/une-ecole-sur-3-campus/actualites/lassociation-polyjoule-lance-son-application-mobile" target="_blank" rel="noopener noreferrer">
                <Bold>Polyjoule</Bold>
            </StyledHref> is a multi-platform application built using <Bold>Unity</Bold> which is composed of a game, players are able to share their highscores.
        </Typography>,       
        styledDescriptionMobile:  <Typography variant="body1" align="center" style={{fontSize:'1rem'}} >
            <StyledHref variant="caption" href="https://polytech.univ-nantes.fr/fr/une-ecole-sur-3-campus/actualites/lassociation-polyjoule-lance-son-application-mobile" target="_blank" rel="noopener noreferrer">
                <Bold>Polyjoule</Bold>
            </StyledHref> is a multi-platform application built using <Bold>Unity</Bold> which is composed of a game, players are able to share their highscores.
        </Typography>
    }
];

export default projectList